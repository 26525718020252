import React, {useState} from "react"
import {Grid} from "@material-ui/core"
import Footer from "../components/Footer"
import {Link} from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import {useAppContext} from "../context/AppContext"
import {groupBy} from "lodash"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import {ExpandLess, ExpandMore} from "@material-ui/icons"
import Collapse from "@material-ui/core/Collapse"
import {Redirect} from "react-router"
import "../styles/FaqsPage.scss"

function FaqsPage(props) {
    const {state} = useAppContext()
    const {productId} = props.match.params
    const isProductFAQs = !!productId
    const [categoryCollapseState, setCategoryCollapseState] = useState({})

    const displayFaqs = () => {
        let faqs = state.faqs.filter(each => each.type === 'general')

        if(isProductFAQs) {
            faqs = state.faqs.filter(each => each.type === 'product' && each.product.id === productId)
            if(faqs.length===0) {
              return <Redirect to={'/notfound'} />
            }
        }

        const groupedFaqs = groupBy(faqs, 'category')
        const faqCategories = Object.keys(groupedFaqs)

        return (
            <List>
                {
                    faqCategories.map(key => {
                        return (
                            <>
                              {!isProductFAQs && <ListItem button={!isProductFAQs}
                                          key={key}
                                          className={'category-list-item'}
                                          onClick={handleCategoryExpandCollapse.bind(this,key)}>
                                    <ListItemText primary={key} className={'category-list-item-text'}/>
                                    {displayExpandCollapse(key)}
                                </ListItem> }
                                <Collapse in={!categoryCollapseState[key] || isProductFAQs} timeout="auto"
                                          unmountOnExit>
                                    <List>{faqPerCategory(groupedFaqs[key])}</List>
                                </Collapse>
                            </>
                        )
                    })
                }
            </List>
        )
    }

    const displayExpandCollapse = (key) => {
        if(isProductFAQs) {
            return null
        }

        return !!categoryCollapseState[key] ? <ExpandLess
                onClick={handleCategoryExpandCollapse.bind(this,key)}/> :
            <ExpandMore onClick={handleCategoryExpandCollapse.bind(this,key)}/>
    }

    const handleCategoryExpandCollapse = (category) => {
        setCategoryCollapseState({
            ...categoryCollapseState,
            [category]: !!!categoryCollapseState[category]
        })
    }

    const faqPerCategory = (faqs) => {
        return faqs.map(each => {
                        return (<ListItem key={faqs.id} className='category-child-list-item'>
                            <div className='question'>{each.question}</div>
                            <div className='answer'
                                dangerouslySetInnerHTML={{__html: each.answer}}/>
                        </ListItem>)
                    })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <div id={'faq-page'}>
                    <div className='header-container'>
                      <div className='breadcrumb-container'>
                        <Breadcrumbs className={'breadcrumbs'}>
                          <Link to="/">{`< Back to Home`}</Link>
                          <Typography className={'breadcrumbs'}>FAQs</Typography>
                        </Breadcrumbs>
                        <span className={'header'}>Frequently Asked Questions </span>
                      </div>
                    </div>
                    <div className={'faq-info-container'}>
                        <div className='faq-item-list'>
                            {displayFaqs()}
                        </div>
                    </div>
                  <img className='faq-info-bg' alt='blur-close-up-droplets'/>
                </div>
            </Grid>
          <Footer/>
        </Grid>
    )
}

export default FaqsPage