import React, {useEffect, useRef, useState} from 'react'
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import {Link, useHistory} from "react-router-dom"
import InputBase from "@material-ui/core/InputBase"
import useAutocomplete from '@material-ui/lab/useAutocomplete'
import * as actionsType from "../util/actionsType"
import {useAppContext} from "../context/AppContext"
import InputAdornment from "@material-ui/core/InputAdornment"
import "../styles/NavDrawer.scss"
import {useMenuState} from "@szhsin/react-menu"

function NavDrawer(props) {
  const {state, dispatch} = useAppContext()
  let history = useHistory()
  const [navigation, setNavigation] = useState([])
  const [autoCompleteValue, setAutoCompleteValue] = useState({})
  const { openMenu, closeMenu, ...menuProps } = useMenuState()
  const ref = useRef(null)

  const onAutoCompleteChange = (event, value) => {
    if (value) {
      history.push("/products/" + value.id)
      setAutoCompleteValue({})
      dispatch({type: actionsType.TOGGLE_NAV_DRAWER, payload: false})
    }
  }

  const {
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: state.products,
    getOptionLabel: option => option.name || '',
    onChange: onAutoCompleteChange,
    value: autoCompleteValue
    //enable this to easily inspect the popup. Select first a value
    // then the popup will not hide
    // debug: true
  })

  useEffect(() => {
    let nav = [
      {name: 'Home', route: '/'},
      {name: 'Products', route: '/menu-products'},
      {name: 'About Us', route: '/about'},
      {name: 'Contact Us', route: '/contact'},
      {name: 'Careers', route: '/careers'},
      {name: 'FAQs', route: '/faq'}
    ]
    setNavigation(nav)
  }, [state.products])

  function getList() {
    return <List className={'nav-menu-list'}>
      {navigation.map((nav, index) => (
        <div key={nav.name + index}>
          <ListItem button component={Link} to={nav.route}
                    className={'nav-menu-item'}
                    onClick={props.toggleDrawer(false)}>
            <ListItemText primary={nav.name} className={'menu-item-text'}/>
          </ListItem>

        </div>
      ))}
    </List>
  }

  return (
    <Drawer open={state.navDrawerOpen} onClose={props.toggleDrawer(false)} className={'nav-drawer-container'}>
      <div style={{display: 'flex', height: 50}}>
        <CloseIcon onClick={props.toggleDrawer(false)} className={'nav-drawer-icon'}/>
        <span style={{marginLeft: 10, fontSize: 13, alignSelf:'center'}}>Menu</span>
      </div>

      <div style={{position: 'relative'}}>
        <InputBase
          className={'nav-bar-input'}
          id="sidebar-search"
          placeholder='Search'
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={'search-icon'}/>
            </InputAdornment>
          }
          {...getInputProps()}
          open={true}
        />
        {groupedOptions.length > 0 && (
          <ul className='options-list-box' {...getListboxProps()} >
            {groupedOptions.map((option, index) => (
              <li key={option.name + index}{...getOptionProps({option, index})}>
                <div className={'option-item'}>
                  <div className={'item-text wrapped'}>{option.name}</div>
                  <div className={'sub-item-text wrapped'}>{option.details}</div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {getList()}
    </Drawer>
  )
}

export default NavDrawer