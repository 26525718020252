import React, {useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid"
import Footer from "../components/Footer"
import {Link} from "react-router-dom"
import {useAppContext} from "../context/AppContext"
import {downloadFile, getVideoId} from "../util/util"
import {URL_REGEX_PATTERN} from "../util/constants"
import Typography from "@material-ui/core/Typography"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import YouTube from "react-youtube"
import Image from "../components/Image"
import {Redirect} from "react-router"
import "../styles/ProductsPage.scss"
import useWindowSize from "../hooks/useWindowSize"


function ProductsPage(props) {
  const {state} = useAppContext()
  const {productId} = props.match.params
  const initialProductState = {name: '', details: '', image: {}, downloads: [], videoUrl: ''}
  const [product, setProduct] = useState(initialProductState)
  const windowSize = useWindowSize()

  useEffect(() => {
    if (state.products && state.products.length !== 0) {
      setProduct(state.products.find(data => data.id === productId) || initialProductState)
    }
  }, [state.products, productId])

  if (state.products && state.products.length !== 0) {
    if(!state.products.find(data => data.id === productId)) {
      return <Redirect to={'/notfound'}/>
    }
  }

  const displayProductFAQs = () => {
    const faqs = state.faqs.filter(faq => faq.type === 'product' && faq.product.id === product.id)
    if(!!!faqs.length) {
      return null
    }

    return <div className={'learn-more'}>
        <Link to={'/faq/'+product.id} >
          Learn More
        </Link>
    </div>
  }

  const displayDownloadableContent = () => {
    const downloadableContents = product.downloads.map((item, index) => (
        <a key={index}
           href={'#'}
           onClick={downloadFile.bind(this, item)}
        >{item.displayName}</a>
    ))

    if(!!!downloadableContents.length) {
      return null
    }

    return (
        <div className={'downloadable-container'}>
          <span className={'downloadable-header'}>Downloadable Content</span>
          {downloadableContents}
        </div>
    )
  }

  function getVideoSection(videoUrl) {
    if(videoUrl && URL_REGEX_PATTERN.test(videoUrl)) {
      return <div id='yt-vid-container'>
        <div className={'product-demo-title'}>Demo</div>
        <div style={{marginTop: '5px', marginBottom: '30px'}}>
          <YouTube
            className={'product-video'}
            videoId={getVideoId(videoUrl)}
          />
        </div>
      </div>
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div className={'product-details-container'}>
            <Breadcrumbs className={'breadcrumbs'}>
              <Link to="/">{`< Back to Home`}</Link>
              <Typography className={'breadcrumbs'}>Products</Typography>
              <Typography className={'breadcrumbs'}>{product.name}</Typography>
            </Breadcrumbs>
            <div className={'product-title'}>{product.name}</div>
            <div className={product.videoUrl && URL_REGEX_PATTERN.test(product.videoUrl) ? 'product-details' : 'product-details-no-vid'}>
              {product.details}
            </div>
            {windowSize.width > 425? getVideoSection(product.videoUrl) : null  }
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} id={'product-media-container'}>
          <div className={'image-container'}>
            <Image
              alt={'center'}
              src={product.image.imageUrl}
              width={'100%'}
              height={'100%'}
            />
            {windowSize.width > 425? displayProductFAQs() : null  }
          </div>
          <img className={'background-nature'} alt='nature'/>
          {windowSize.width > 425? null : getVideoSection(product.videoUrl)  }
          {windowSize.width > 425? null : displayProductFAQs()  }
          {displayDownloadableContent()}
        </Grid>
        <Footer/>
      </Grid>
    </>

  )
}


export default ProductsPage
