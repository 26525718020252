import React, {useState} from 'react'
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import {Link} from "react-router-dom"
import PrivateRoute from "../PrivateRoute"
import ProductMaintenancePage from "./maintenance/ProductMaintenancePage"
import firebase from "../firebase"
import AboutUsMaintenancePage from "./maintenance/AboutUsMaintenancePage"
import ContactUsMaintenancePage from "./maintenance/ContactUsMaintenancePage"
import UsersMaintenancePage from "./maintenance/UsersMaintenancePage"
import CareerMaintenancePage from "./maintenance/CareerMaintenancePage"
import FaqsMaintenancePage from "./maintenance/FaqsMaintenancePage"
import {useAuthContext} from "../context/AuthContext"
import Collapse from "@material-ui/core/Collapse"
import {ExpandLess, ExpandMore} from "@material-ui/icons"
import {makeStyles} from "@material-ui/core/styles"
import {useLocation} from "react-router"
import LandingMaintenancePage from "./maintenance/LandingMaintenancePage"
import NewsMaintenancePage from "./maintenance/NewsMaintenancePage"
import MenuMaintenancePage from "./maintenance/MenuMaintenancePage";

const navigation = [
  {name: 'Products', route: '/products'},
  {name: 'About Us', route: '/about'},
  {name: 'Contact Us', route: '/contact'},
  {name: 'Careers', route: '/careers'},
  {name: 'FAQs', route: '/faqs'},
  {name: 'Users', route: '/users'},
  {name: 'News', route: '/news'},
  {name: 'Product Menu', route: '/menu'}
]

const useStyles = makeStyles({
  list: {
    width: 138,
    paddingTop: 0,
    paddingBottom: 0,
    position: 'absolute',
    zIndex: 2
  },
  listStyle: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    zIndex: 1,
    backgroundColor: '#E8F3F8',
    margin: '0px',
    padding: '0px',
    position: 'relative'
  },
  container: {
    height: '100%'
  },
  adminSubItem: {
    height: 35,
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: 14,
    textDecoration: 'underline',
    "&:hover": {
      backgroundColor: '#FFFFFF'
    }
  },
  listItem: {
    zIndex: 1,
    "&.Mui-selected": {
      backgroundColor: '#A4BCC2',
      zIndex: 1
    }
  },
  listItemText: {
    paddingLeft: 50,
    fontWeight: 'bold',
    color: '#616161'
  },
  listItemTextSelected: {
    paddingLeft: 50,
    fontWeight: 'bold',
    color: '#FFFFFF'
  }
})

function AdminHomePage() {
  const location = useLocation()
  const style = useStyles()
  const {currentUser} = useAuthContext()
  const [adminMenuExpand, setAdminMenuExpand] = useState(false)

  const showAdminMenu = () => {
    if (Object.entries(currentUser).length === 0) {
      return ''
    }

    const name = !!currentUser.displayName ?
      currentUser.displayName :
      currentUser.email.split('@')[0]

    const menuStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(0, -50%)',
      display: 'block',
      cursor: 'pointer',
      font: 'Medium 18px Roboto',
      textDecoration: 'underline',
      color: '#FFFFFF'
    }

    const arrowIcon = adminMenuExpand ? <ExpandLess/> : <ExpandMore/>

    return (
      <div style={menuStyle}
           onClick={() => setAdminMenuExpand(!adminMenuExpand)}>
        <div style={{display: 'flex'}}>
          {name}
          {arrowIcon}
        </div>
        <div style={{position: 'relative'}}>
          <Collapse in={adminMenuExpand} timeout="auto" unmountOnExit>
            <List classes={{root: style.list}}>
              <ListItem button key={''} classes={{root: style.adminSubItem}}>
                <ListItemText primary={'Log Out'}
                              onClick={() => firebase.auth().signOut()}
                              onMouseLeave={() => setAdminMenuExpand(
                                !adminMenuExpand)}/>
              </ListItem>
            </List>
          </Collapse>
        </div>
      </div>
    )
  }

  return (
    <Grid container className={style.container}>
      <Grid item xs={2} style={{backgroundColor: '#E8F3F8'}}>
        <div style={{
          position: 'relative',
          zIndex: 2,
          width: '100%',
          backgroundColor: '#81A8B8'
        }}>
          <img
            style={{width: '90px', height: '46px', margin: 'auto', padding: 15}}
            alt={'nature background'}
            src={'/images/stikwel-silver-2.svg'}/>
          {showAdminMenu()}
        </div>
        <List classes={{root: style.listStyle}}>
          {navigation.map((nav) => (
            <ListItem button
                      selected={location.pathname === nav.route}
                      key={nav.name}
                      component={Link}
                      to={nav.route}
                      classes={{
                        root: style.listItem,
                      }}>
              <ListItemText primary={nav.name}
                            classes={{
                              primary: location.pathname === nav.route
                                ? style.listItemTextSelected
                                : style.listItemText
                            }}/>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={10} style={{backgroundColor: '#C2CBCE'}}>
        <div style={{
          backgroundColor: '#C2CBCE',
          height: '100%',
          position: 'relative',
          padding: 20,
          boxSizing: 'border-box',
          display: 'flex'
        }}>
          <PrivateRoute exact path="/products"
                        component={ProductMaintenancePage}/>
          <PrivateRoute exact path="/about" component={AboutUsMaintenancePage}/>
          <PrivateRoute exact path="/contact"
                        component={ContactUsMaintenancePage}/>
          <PrivateRoute exact path="/careers"
                        component={CareerMaintenancePage}/>
          <PrivateRoute exact path="/users" component={UsersMaintenancePage}/>
          <PrivateRoute exact path="/faqs" component={FaqsMaintenancePage}/>
          <PrivateRoute exact path="/news" component={NewsMaintenancePage}/>
          <PrivateRoute exact path="/menu" component={MenuMaintenancePage}/>

        </div>
      </Grid>
    </Grid>
  )
}

export default AdminHomePage
