import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles/index"
import Modal from "@material-ui/core/Modal/index"
import Button from "@material-ui/core/Button/index"
import Link from "@material-ui/core/Link/index"
import Grid from "@material-ui/core/Grid/index"
import firebase from "../../firebase"
import YouTube from 'react-youtube'
import UploadDownloadables from "../UploadDownloadables"
import ImageUpload from "../ImageUpload"
import Input from "../Input"
import InputBase from "@material-ui/core/InputBase"
import {useDialogContext} from "../../context/DialogContext"
import {getVideoId} from "../../util/util"
import {trackPromise} from "react-promise-tracker"
import useFetch from "../../hooks/useFetch"
import {FIRESTORE_COLLECTION, URL_REGEX_PATTERN} from "../../util/constants"

const db = firebase.firestore()
const storage = firebase.storage()

const useStyles = makeStyles(theme => ({
    paper: {
        width: 983,
        backgroundColor: theme.palette.background.paper
    },
    inputBaseRoot: {
        fontSize: 14,
        color: '#000000DE',
        opacity: 1,
        padding: '15px 10px',
        backgroundColor: '#F2F2F2'
    },
    button: {
        backgroundColor: '#1F5B84',
        color: '#FFFFFF',
        width: '156px',
        height: '54px',
        fontSize: 14,
        boxShadow: '0px 2px 2px #0000003D',
        '&.Mui-disabled': {
            backgroundColor: '#BDBDBD',
        },
        '&.btn-view': {
            backgroundColor: '#8A8A8A'
        }
    }
}))

const MAX_IMAGE_SIZE = 2097152
const MAX_UPLOAD_SIZE = 2097152

function ProductModal(props) {
    const {showConfirmDialog, showAlert} = useDialogContext()
    const {fetchProducts} = useFetch()
    const classes = useStyles()
    const [product, setProduct] = useState({name: '', details: '', image: {}, downloads: [], videoUrl: ''})

    useEffect(() => {
        props.data.id && setProduct(props.data)
    }, [props.data])

    const onChangeInput = (field, event) => {
        const value = event.target.value
        setProduct({
            ...product,
            [field]: value
        })
    }

    const addNewProduct = (e) => {
        const addOrEdit = props.data.id ? 'Edit' : 'Add'
        showConfirmDialog({
            title: addOrEdit + ' Product',
            message: `Are you sure you want to ${addOrEdit.toLowerCase()} product ` + product.name + '?',
            confirmFn: () => {
                onSubmit(e)
                props.closeFn()
            },
            type: addOrEdit
        })
    }

    const handleDownloadableUploadChange = (downloads) => {
        setProduct({...product, downloads})
    }

    const handleImageUploadChange = (image) => {
        setProduct({...product, image})
    }

    const checkIfHasFileToUpload = (object) => {
        return object.hasOwnProperty("fileToUpload") && object.fileToUpload.indexOf('base64')
    }

    const cleanBase64ForFirebase = (fileToUpload) => {
        return fileToUpload.substr( fileToUpload.indexOf('base64') + 7)
    }

    const getItemsToUpload = () => {
        let items = []
        if (checkIfHasFileToUpload(product.image)) {
            items.push({
                child: "images/" + product.image.storageFileName,
                fileToUpload: cleanBase64ForFirebase(product.image.fileToUpload)
            })
        }
        product.downloads.forEach(download => {
            if (checkIfHasFileToUpload(download)) {
                items.push({
                    child: "downloads/" + download.storageFileName,
                    fileToUpload: cleanBase64ForFirebase(download.fileToUpload)
                })
            }
        })
        return items
    }

    const cleanData = (product, uploadItems) => {
        //remove all fileToUpload
        let newProduct = JSON.parse(JSON.stringify(product))
        //image is always first in uploading
        if(props.data.id) {
            if(!!uploadItems.length && uploadItems[0].child.includes('images')) {
                newProduct.image.imageUrl = uploadItems[0].storageUrl
            }
        } else {
            newProduct.image.imageUrl = uploadItems[0].storageUrl
        }
        delete newProduct.image.fileToUpload

        //remove blank displayname, storagefilename and filetoupload
        newProduct.downloads = newProduct.downloads.filter(download => {
            delete download.fileToUpload
            if(download.displayName && download.storageFileName){
                //this is just getting the storage URL to not download again to save money
                const item = uploadItems.filter( item => item.child.replace("downloads/","") === download.storageFileName )
                if(props.data.id) {
                    if(!!item.length) {
                        download.downloadUrl =  item[0].storageUrl
                    }
                } else {
                    download.downloadUrl =  item[0].storageUrl
                }
                return true
            }
            return false
        })
        return newProduct
    }

    const onSubmit = event => {
        event.preventDefault()
        // DB saving
        let uploadItems = getItemsToUpload()
        //if nothing to upload, it will automatically go to success callback
        Promise.all(
            uploadItems.map(item => saveFileToStorage(item))
        ).then(() => {
            let data = cleanData(product, uploadItems)
            if (props.data.id) {
                //update product
                return db.collection('products').doc(data.id).set(data)
            }
            //New Product
            return db.collection('products').add(data)
        })
        .then(() => displayAlert(true))
        .catch(e => displayAlert(false, e))
    }

    const displayAlert = (success, error) => {
        showAlert({
            type: success ? 'success' : 'error',
            title: props.data.id ? 'Update' : 'Add' + (success ? ' Success':' Error'),
            message: success ? 'Successfully' + (props.data.id ? ' updated ' : ' added ') + 'product!' : error.message
        })
        trackPromise(fetchProducts())
    }

    const saveFileToStorage = (item) => {
        return storage.ref().child(item.child).putString(item.fileToUpload, "base64")
            .then(async(snapshot) => {
                console.log("success insert ", item)
                item.storageUrl = await snapshot.ref.getDownloadURL()
            }).catch(error => console.log('ERROR UPLOADING TO STORAGE', item, error))
    }

    const shouldDisable = () => {
        const disable = product.name.length > 0 &&
            product.details.length > 0 &&
            Object.entries(product.image).length > 0
        return !disable
    }

    return (
        <div id={'productModal'}>
            <Modal className='modal' style={{display: 'flex'}} open>
                <div style={{margin: 'auto'}} className={classes.paper}>
                    <div style={{
                        width: 983,
                        height: 68,
                        borderBottom: '1px solid #707070',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 36
                    }}>
                        <span style={{
                            marginLeft: 20,
                            fontSize: '1.25vw', fontWeight: 'bold'
                        }}>{props.data.id && props.view ? 'View Product' : props.edit ? 'Update Product' : 'New Product'}</span>
                    </div>
                    <Grid container style={{padding: '0 51px'}}>
                        <Grid item xs={6} style={{paddingRight: '110px'}}>
                            <div>
                                <Input maxLength={50}
                                       label={'Product Name'}
                                       onChangeFn={onChangeInput.bind(this, 'name')}
                                       value={product.name}
                                       error={product.name.length === 0}
                                       readOnly={props.view}
                                />
                            </div>
                            <div>
                                <Input className='product-description'
                                       multiline
                                       rowsMax={13}
                                       maxLength={1000}
                                       label={'Product Description'}
                                       rows={13}
                                       onChangeFn={onChangeInput.bind(this, 'details')}
                                       value={product.details}
                                       error={product.details.length === 0}
                                       readOnly={props.view}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <span style={{
                                    fontSize: 12,
                                    lineHeight: '28px',
                                    color: '#0000008A'
                                }}>Product Image</span>
                                <ImageUpload
                                    name={'product-image'}
                                    maxSize={MAX_IMAGE_SIZE}
                                    value={product.image}
                                    actionFn={handleImageUploadChange.bind(this)}
                                    editable={props.edit || !props.data.id}
                                />
                            </div>
                            <div>
                                <span style={{
                                    fontSize: 12,
                                    lineHeight: '28px',
                                    color: '#0000008A'
                                }}>Video URL</span>
                                <div>
                                    <InputBase
                                        style={{width: '424px', marginBottom: 10}}
                                        classes={{input: classes.inputBaseRoot}}
                                        value={product.videoUrl}
                                        onChange={onChangeInput.bind(this, 'videoUrl')}
                                        error={product.videoUrl && !URL_REGEX_PATTERN.test(product.videoUrl)}
                                    />
                                </div>
                                <YouTube
                                    videoId={getVideoId(product.videoUrl)}
                                    opts={{width: "424px", height: "224px"}}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9} style={{height: 200}}>
                            <div style={{height: 60}}>
                                <span style={{
                                    fontSize: 12,
                                    lineHeight: '14px',
                                    color: '#0000008A'
                                }}>Downloadable Content</span>
                                <UploadDownloadables
                                    maxSize={MAX_UPLOAD_SIZE}
                                    values={product.downloads}
                                    actionFn={handleDownloadableUploadChange.bind(this)}
                                    editable={props.edit || !props.data.id}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3} style={{alignSelf: 'center'}}>
                            <div style={{float: 'right', display: 'flex', flexDirection: 'column'}}>
                                <Button className={props.view ? 'btn-view' : 'btn-edit'}
                                        classes={{root: classes.button}}
                                        onClick={props.view ? props.closeFn : addNewProduct}
                                        disabled={shouldDisable()}>
                                    {props.view ? 'Close' : props.edit ? 'Save Changes' : 'Add Product'}
                                </Button>
                                <Link style={{paddingTop: 10, color: '#717171', fontSize: 14, marginLeft: 'auto'}}
                                      href='#' onClick={props.closeFn}>Cancel</Link>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

export default ProductModal
