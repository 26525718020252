import React, {useEffect, useState} from 'react'
import Modal from "@material-ui/core/Modal"
import Grid from "@material-ui/core/Grid"
import {useDialogContext} from "../../context/DialogContext"
import {merge} from "../../util/util"
import {trackPromise} from "react-promise-tracker"
import Input from "../Input"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import firebase from "../../firebase"
import {makeStyles} from "@material-ui/core/styles"
import ImageUpload from "../ImageUpload"
import useFetch from "../../hooks/useFetch"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import moment from "moment"
import {useAuthContext} from "../../context/AuthContext"
import {KeyboardDatePicker} from "@material-ui/pickers"
import InputBase from "@material-ui/core/InputBase/InputBase"

const db = firebase.firestore()
const storage = firebase.storage()

const useStyles = makeStyles(theme => ({
    paper: {
        width: 990,
        backgroundColor: theme.palette.background.paper,
        margin:'auto'
    },
    titleStyle: {
        padding: '1.042vw 0 1.042vw 1.042vw',
        borderBottom: '1px solid #707070'
    },
    bodyStyle: {
        padding: '1.823vw 0'
    },
    buttonContainerStyle: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-end',
        margin: '40px 50px'
    },
    modalButtonStyle: {
        backgroundColor: '#1F5B84',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalButtonViewStyle: {
        backgroundColor: '#8A8A8A',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalLinkStyle: {
        color: '#717171',
        textDecoration: 'underline',
        fontSize: '0.729vw',
        fontWeight: 550
    },
    inputBaseRoot: {
      fontSize: 14,
      color: '#000000DE',
      opacity: 1,
      padding: '15px 10px',
      backgroundColor: '#F2F2F2'
    }
}))

const initialNewsState = {
    image: {},
    title: '',
    url: '',
    createdAt: '',
    createdBy: '',
    newsDate: moment(),
    description: ''
}
const MAX_IMAGE_SIZE = 2097152

function NewsModal(props) {
    const {showConfirmDialog, showAlert} = useDialogContext()
    const classes = useStyles()
    const [news, setNews] = useState(initialNewsState)
    const [view, setView] = useState(false)
    const {fetchNews} = useFetch()
    const {currentUser} = useAuthContext()

    useEffect(() => {
        props.data.id && setNews(merge(props.data, {password: '', confirmPassword: '', oldPassword: ''}))
        setView(props.view)
    }, [props.data])

    const onChangeInput = (field, event) => {
      let value
      switch (field) {
        case 'active' : {
          value = event.target.checked
          break
        }
        case 'newsDate': {
          value = event
          break
        }
        default : {
          value = event.target.value
        }
      }
      setNews({
        ...news,
        [field]: value
      })
    }

    const handleImageUploadChange = (image) => {
        setNews({...news, image})
    }

    const save = (event) => {
        const addEdit = news.id ? 'edit' : 'add'
        showConfirmDialog({
            title: `${news.id ? 'Edit':'Add'}` + ' News',
            message: 'Are you sure you want to ' + addEdit + ' News?',
            confirmFn: () => {
                onSubmit(event)
                props.closeFn()
            },
            type: addEdit
        })
    }

    const checkIfHasFileToUpload = (object) => {
        return object.hasOwnProperty("fileToUpload") && object.fileToUpload.indexOf('base64')
    }

    const cleanBase64ForFirebase = (fileToUpload) => {
        return fileToUpload.substr( fileToUpload.indexOf('base64') + 7)
    }

    const getItemsToUpload = () => {
        let items = []
        if (checkIfHasFileToUpload(news.image)) {
            items.push({
                child: "images/" + news.image.storageFileName,
                fileToUpload: cleanBase64ForFirebase(news.image.fileToUpload)
            })
        }
        return items
    }

    const cleanData = (news, uploadItem) => {
        //remove all fileToUpload
        let newNews = JSON.parse(JSON.stringify(news))
        //downloadURL right away so that it will not call storage always
        if(props.data.id) {
            if(!!uploadItem.length) {
                newNews.image.imageUrl = uploadItem[0].imageUrl
            }
        } else {
            newNews.image.imageUrl = uploadItem[0].imageUrl
        }
        delete newNews.image.fileToUpload
        return newNews
    }

    const onSubmit = async event => {
        event.preventDefault()
        // DB saving
        let uploadItems = getItemsToUpload()
        //if nothing to upload, it will automatically go to success callback
        Promise.all(
            uploadItems.map(item => saveFileToStorage(item))
        ).then(() => {
            let data = cleanData(news, uploadItems)
            if (props.data.id) {
                //update product
                return db.collection('news').doc(data.id).set(data)
            }
            //New Productnews
            data.createdAt = moment(new Date()).format('MM/DD/YYYY hh:mm A')
            data.createdBy = currentUser.email
            return db.collection('news').add(data)
        })
            .then(() => displayAlert(true))
            .catch(e => displayAlert(false, e))
    }

    const saveFileToStorage = (item) => {
        return storage.ref().child(item.child).putString(item.fileToUpload, "base64")
            .then(async (snapshot)  => {
                item.imageUrl = await snapshot.ref.getDownloadURL()
            }).catch(error => console.log('ERROR UPLOADING TO STORAGE', item, error))
    }

    const displayAlert = (success, error) => {
        showAlert({
            type: success ? 'success' : 'error',
            title: props.data.id ? 'Update' : 'Add' + (success ? ' Success':' Error'),
            message: success ? 'Successfully' + (props.data.id ? ' updated ' : ' added ') + 'item!':  error.message
        })
        trackPromise(fetchNews())
    }

    const handleModalCancel = (event) => {
        event.preventDefault()
        props.closeFn()
    }

    const displayModalHeader = () => {
        let modalTitle = news.id ? 'Edit News Item' : 'New News Item'
        if (view) {
            modalTitle = 'View News'
        }

        return (
            <Grid container item xs={12} className={classes.titleStyle}>
                <div style={{alignItems: 'center', display: 'flex'}}>
                    <span style={{fontSize: '1.250vw', fontWeight: 'bold'}}>
                        {modalTitle}
                    </span>
                </div>
            </Grid>
        )
    }

    const displayModalBody = () => {

      return (
            <Grid container className={classes.bodyStyle}>
              <Grid item xs={6} style={{padding:'0 40px'}}>
                <div style={{width: '175px', paddingBottom:'20px'}}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    label="News Date"
                    format="DD MMM yyyy"
                    value={news.newsDate}
                    onChange={onChangeInput.bind(this, 'newsDate')}
                  />
                </div>

                <Input maxLength={50}
                       label={'News Title'}
                       onChangeFn={onChangeInput.bind(this, 'title')}
                       value={news.title}
                       error={news.title.length === 0}
                       readOnly={view}
                       fullWidth
                />

                <Input maxLength={1000}
                       label={"News Description"}
                       value={news.description}
                       error={news.description.length === 0}
                       onChangeFn={onChangeInput.bind(this, 'description')}
                       rowsMax={6}
                       rows={6}
                       multiline
                       readOnly={view}
                       fullWidth
                />

              </Grid>
              <Grid item xs={6} style={{padding:'0 40px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <ImageUpload
                    name={'news-image'}
                    maxSize={MAX_IMAGE_SIZE}
                    value={news.image}
                    actionFn={handleImageUploadChange.bind(this)}
                    editable={props.edit || !props.data.id}
                  />
                  <span style={{
                    marginTop: '40px',
                    fontSize: '12px',
                    lineHeight: '28px',
                    color: '#0000008A'
                  }}>News URL</span>
                  <div>
                    <InputBase
                      style={{width: '424px', marginBottom: 10}}
                      classes={{input: classes.inputBaseRoot}}
                      value={news.url}
                      onChange={onChangeInput.bind(this, 'url')}
                      error={news.url.length === 0}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
        )
    }

    const isNewsValid = () => {
        const titleValid = news.title.length > 0
        const descriptionValid = news.description.length > 0
        const urlValid = news.url.length > 0
        const imageValid = Object.entries(news.image).length > 0

        return titleValid && descriptionValid && urlValid && imageValid
    }

    const displayModalButtons = () => {
        const buttonAction = (event) => {
            view ? handleModalCancel(event) : save(event)
        }

        const linkAction = (event) => {
            view ? setView(false) : handleModalCancel(event)
        }

        return (
            <Grid item xs={12} className={classes.buttonContainerStyle}>
                <div style={{paddingBottom: '0.677vw'}}>
                    <Button variant="contained" size="large"
                            disabled={!isNewsValid()}
                            className={view ? classes.modalButtonViewStyle : classes.modalButtonStyle}
                            onClick={buttonAction.bind(this)}>
                        {view ? 'Close' : news.id ? 'Save Changes' : 'Add News'}
                    </Button>
                </div>
                <div>
                    <Link className={classes.modalLinkStyle}
                          href='#' onClick={linkAction.bind(this)}>{view ? 'Edit News' : 'Cancel'}</Link>
                </div>
            </Grid>
        )
    }

    return (
        <div id={'newsModal'}>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={'modal'}
                style={{display:"flex"}}
                open
            >
                <div className={classes.paper}>
                    <Grid container>
                        {displayModalHeader()}
                        {displayModalBody()}
                        {displayModalButtons()}
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

export default NewsModal
