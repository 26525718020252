import React, {useEffect, useState} from 'react'
import Modal from "@material-ui/core/Modal"
import Grid from "@material-ui/core/Grid"
import Input from "../Input"
import ImageUpload from "../ImageUpload"
import {makeStyles} from "@material-ui/core/styles"
import {useDialogContext} from "../../context/DialogContext"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import {useAppContext} from "../../context/AppContext"
import {MENU_MODAL_TYPE} from "../../util/constants"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ReactQuill from "react-quill"

const MAX_IMAGE_SIZE = 2097152

const useStyles = makeStyles(theme => ({
    paper: {
        width: 591,
        margin: 'auto',
        backgroundColor: theme.palette.background.paper
    },
    titleStyle: {
        padding: '1.042vw 0 1.042vw 1.042vw',
        borderBottom: '1px solid #707070'
    },
    buttonContainerStyle: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-end',
        margin: '40px 50px'
    },
    modalButtonStyle: {
        backgroundColor: '#1F5B84',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalButtonViewStyle: {
        backgroundColor: '#8A8A8A',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalLinkStyle: {
        color: '#717171',
        textDecoration: 'underline',
        fontSize: '0.729vw',
        fontWeight: 550
    }
}))

function MenuModal(props) {
    const {showConfirmDialog} = useDialogContext()
    const {state, dispatch} = useAppContext()
    const classes = useStyles()
    const [menu, setMenu] = useState({name:'', image: '', linkURL: '', description: ''})
    const [view, setView] = useState(false)
    const {type, isNew, isEdit} = props

    useEffect(() => {
        if(props.isEdit) {
            setMenu(props.menus)
        }
    }, [])

    const onChangeInput = (field, event) => {
        let value
        switch (field) {
            case 'active' : {
                value = event.target.checked
                break
            }
            case 'menuDate': {
                value = event
                break
            }
            case 'description': {
                value = event
                break
            }
            default : {
                value = event.target.value
            }
        }
        setMenu({
            ...menu,
            [field]: value
        })
    }

    const handleImageUploadChange = (image) => {
        setMenu({...menu, image})
    }

    const save = (event) => {
        const addEdit = !!menu.menuId ? 'edit' : 'add'
        showConfirmDialog({
            title: `${!!menu.menuId ? 'Edit':'Add'}` + ' Menu',
            message: 'Are you sure you want to ' + addEdit + ' Menu?',
            confirmFn: () => {
                onSubmit(event)
                props.closeFn()
            },
            type: addEdit
        })
    }

    const getMenuNode = (type, isRootLevel) => {
        let menuNode = {
            name: menu.name,
            linkURL: type === MENU_MODAL_TYPE.PRODUCT ? menu.linkURL : '',
            menuId: menu.name.replace(/\s+/g, '') ,
            nodes: isNew ? [] : props.menus.nodes,
            depth: 0,
            image: menu.image || {},
            description: menu.description
        }

        if(!isRootLevel) {
            menuNode.depth = isNew ? props.menus.depth + 1 : props.menus.depth
            menuNode.image.imageUrl = ''
        }

        return menuNode
    }

    const onSubmit = async event => {
        event.preventDefault()
        let newMenus = state.menus
        const isRootLevel = !props.menus || (isEdit && props.menus.depth === 0)
        let newNode

        if(isNew) {
            newNode = getMenuNode(type, isRootLevel)
            if(isRootLevel) {
                newMenus.push(newNode)
            } else {
                props.menus.nodes.push(newNode)
            }
        }

        if(isEdit) {
            newNode = getMenuNode(type, isRootLevel)
            updateMenuNode(newMenus, newNode)
        }

        // Apparently, dispatch is not needed as state.menus / props.menus
        // will be updated regardless if dispatched
        // dispatch({type: actionsType.SET_MENUS, payload: newMenus})
    }

    const updateMenuNode = (newMenus, newNode) => {
        newMenus.forEach(function(menu, index) {
            if(menu.nodes) {
                updateMenuNode(menu.nodes, newNode)
            }
            if(objectEqual(menu, props.menus)) {
                this[index] = newNode
            }
        }, newMenus)
    }

    const objectEqual = (obj1, obj2) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    const menuType = (menuType) => {
        if(MENU_MODAL_TYPE.CATEGORY === menuType) {
            return 'Category'
        }

        if(MENU_MODAL_TYPE.SUB_CATEGORY === menuType) {
            return 'Sub Category'
        }

        if(MENU_MODAL_TYPE.PRODUCT === menuType) {
            return 'Product'
        }

        return ''
    }

    const displayModalHeader = () => {
        let modalTitle = 'New Menu - ' + menuType(props.type)
        if (isEdit) {
            modalTitle = 'Edit Menu - ' + menuType(props.type)
        }

        return (
            <Grid container item xs={12} className={classes.titleStyle}>
                <div style={{alignItems: 'center', display: 'flex'}}>
                    <span style={{fontSize: '1.250vw', fontWeight: 'bold'}}>
                        {modalTitle}
                    </span>
                </div>
            </Grid>
        )
    }

    const handleModalCancel = (event) => {
        event.preventDefault()
        props.closeFn()
    }

    const displayModalButtons = () => {
        const buttonAction = (event) => {
            view ? handleModalCancel(event) : save(event)
        }

        const linkAction = (event) => {
            view ? setView(false) : handleModalCancel(event)
        }

        return (
            <Grid item xs={12} className={classes.buttonContainerStyle}>
                <div style={{paddingBottom: '0.677vw'}}>
                    <Button variant="contained" size="large"
                            className={view ? classes.modalButtonViewStyle : classes.modalButtonStyle}
                            onClick={buttonAction.bind(this)}>
                        {view ? 'Close' : !!menu.menuId ? 'Save Changes' : 'Add Menu'}
                    </Button>
                </div>
                <div>
                    <Link className={classes.modalLinkStyle}
                          href='#' onClick={linkAction.bind(this)}>{view ? 'Edit Menu' : 'Cancel'}</Link>
                </div>
            </Grid>
        )
    }

    const onProductChanged = (event, newValue) => {
        //temp solution
        if(newValue) {
            const product = state.products.filter((product) => product.name === newValue)[0]
            setMenu({name: newValue, image: '', linkURL: product.id })
        }

    }

    const onCategoryChanged = (event) => {
        setMenu({...menu, name: event.target.value})
    }

    const displayModalBody = () => {
        return (
            <div style={{padding: '1.823vw 2.865vw 0 2.865vw', width: '100%'}}>
                {type === MENU_MODAL_TYPE.SUB_CATEGORY &&
                    <TextField id="category-input" 
                               label="Standard" 
                               value={menu.name} 
                               onChange={onCategoryChanged.bind(this)}
                               fullWidth
                    />
                }

                {type === MENU_MODAL_TYPE.CATEGORY &&
                    <Input maxLength={50}
                           label={'Menu Name'}
                           onChangeFn={onChangeInput.bind(this, 'name')}
                           value={menu.name }
                           error={menu.name.length === 0}
                           readOnly={view}
                           fullWidth
                    />
                }

                {type === MENU_MODAL_TYPE.PRODUCT &&
                    <Autocomplete
                        id="products"
                        options={state.products.map((option) => option.name)}
                        renderInput={(params) => (
                            <TextField {...params} label="Products" variant="outlined" />
                        )}
                        value={menu.name}
                        onChange={onProductChanged.bind(this)}

                    />
                }

                {((!props.menus || (isEdit && props.menus.depth === 0)) && (type === MENU_MODAL_TYPE.PRODUCT || type === MENU_MODAL_TYPE.CATEGORY)) &&
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <ImageUpload
                            name={'menu-image'}
                            maxSize={MAX_IMAGE_SIZE}
                            value={menu.image}
                            actionFn={handleImageUploadChange.bind(this)}
                            editable={true}
                        />
                    </div>
                }

                {type === MENU_MODAL_TYPE.CATEGORY &&
                    <div style={{height:300}}>
                        <ReactQuill
                            value={!!menu.description ? menu.description : ''} 
                            style={{
                                border: view ? 'none' : '1px solid #ccc',
                                minHeight: '90%',
                                maxHeight: '90%',
                                overflowY: 'auto'
                            }}
                            modules={{toolbar: view ? '' : ['bold', 'italic', 'underline']}}
                            onChange={onChangeInput.bind(this, 'description')} readOnly={view} placeholder={'Compose Category Description Content...'}/>
                    </div>
                }
            </div>
        )
    }

    return (
        <div id={'menuModal'}>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display: 'flex'}}
                className={'modal'}
                open
            >
                <div className={classes.paper}>
                    <Grid container>
                        {displayModalHeader()}
                        {displayModalBody()}
                        {displayModalButtons()}
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

export default MenuModal