import React from 'react'

function Image(props) {
    return (
        <img className={props.className}
             alt={props.alt}
             src={props.src}
             height={props.height}
             width={props.width}/>
    )
}

export default Image