import React, {useEffect, useState} from 'react'
import ImageUpload from "../../components/ImageUpload"
import useFetch from "../../hooks/useFetch"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import {useAppContext} from "../../context/AppContext"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import firebase from "../../firebase"
import {useDialogContext} from "../../context/DialogContext"
import Input from "../../components/Input"
import {merge} from "../../util/util"
import {makeStyles} from "@material-ui/core/styles"
import {trackPromise} from "react-promise-tracker"

const db = firebase.firestore()
const storage = firebase.storage()
const center = {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto'
}

const useStyles = makeStyles(() => ({
    landingPageBody: {
        paddingTop: '1.875vw',
        paddingRight: '2.969vw',
        paddingLeft: '2.969vw'
    },
    smallSectionSpacing: {
        paddingTop: '1.563vw'
    },
    sectionSpacing: {
        paddingTop: '2.917vw'
    },
    textfieldAdditionalStyle: {
        margin: '0 0 0 0'
    }
}))

function LandingMaintenancePage() {
    const {state} = useAppContext()
    const {showConfirmDialog} = useDialogContext()
    const [landingPage, setLandingPage] = useState({
        //TODO Review Input Component , no need to always initialize blank string
        firstSection: {image: {}, tagLine: '', paragraph: ''},
        secondSection: {image: {}, paragraph: ''},
        thirdSection: {image: {}, paragraph: ''}
    })
    const [error, setError] = useState({})
    const [view, setView] = useState(true)
    const classes = useStyles()

    useFetch(FIRESTORE_COLLECTION.LANDING_PAGE)

    useEffect(() => {
        state.landingPage && setLandingPage(state.landingPage)
    }, [state.landingPage])

    const handleImageUploadChange = (image, name) => {
        setLandingPage({
            ...landingPage,
            [name]: {
                ...landingPage[name],
                image: image
            }
        })
    }
    const checkIfHasFileToUpload = (object) => {
        return object.hasOwnProperty("fileToUpload") && object.fileToUpload.name
    }

    const getItemsToUpload = () => {
        let items = []
        if (checkIfHasFileToUpload(landingPage.firstSection.image)) {
            items.push({
                child: "images/" + landingPage.firstSection.image.storageFileName,
                fileToUpload: landingPage.firstSection.image.fileToUpload
            })
        }

        if (checkIfHasFileToUpload(landingPage.secondSection.image)) {
            items.push({
                child: "images/" + landingPage.secondSection.image.storageFileName,
                fileToUpload: landingPage.secondSection.image.fileToUpload
            })
        }

        if (checkIfHasFileToUpload(landingPage.thirdSection.image)) {
            items.push({
                child: "images/" + landingPage.thirdSection.image.storageFileName,
                fileToUpload: landingPage.thirdSection.image.fileToUpload
            })
        }

        return items
    }

    const handleSave = (event) => {
        showConfirmDialog({
            title: "Update Landing Page",
            message: 'Are you sure you want to update the landing page?',
            confirmFn: () => {
                onSubmit(event)
                setView(true)
            },
            type: 'edit'
        })
    }

    const cleanData = (data) => {
        //remove all fileToUpload
        let newLandingData = JSON.parse(JSON.stringify(data))
        delete newLandingData.firstSection.image.fileToUpload
        delete newLandingData.firstSection.image.imageUrl
        delete newLandingData.firstSection.id

        delete newLandingData.secondSection.image.fileToUpload
        delete newLandingData.secondSection.image.imageUrl
        delete newLandingData.secondSection.id

        delete newLandingData.thirdSection.image.fileToUpload
        delete newLandingData.thirdSection.image.imageUrl
        delete newLandingData.thirdSection.id

        return newLandingData
    }

    const onSubmit = event => {
        event.preventDefault()
        // DB saving
        let uploadItems = getItemsToUpload()
        //if nothing to upload, it will automatically go to success callback
        trackPromise(Promise.all(
            uploadItems.map(item => saveFileToStorage(item))
        ).then(() => {
            let data = cleanData(landingPage)

            Object.keys(data).forEach(key => {
                db.collection(FIRESTORE_COLLECTION.LANDING_PAGE).doc(key).set(data[key]).catch((error) => setError({message: error}))
            })
        }).catch(error => {
            setError({message: error})
            console.error(error)
        }))
    }

    const saveFileToStorage = (item) => {
        return storage.ref().child(item.child).put(item.fileToUpload)
            .then(() => {
                console.log("success insert ", item)
            }).catch(error => console.log(item, error))
    }

    const onChangeInput = (section, field, event) => {
        const value = event.target.value
        setLandingPage({
            ...landingPage,
            [section]: {
                ...landingPage[section],
                [field]: value
            }
        })
    }

    const displayButtons = () => {
        const linkText = view ? 'Edit Content' : 'Cancel'
        const button = () => {
            if (view) {
                return (
                    <div style={{marginLeft: 'auto', height: '2.813vw', width: '8.151vw'}}/>
                )
            }
            return (
                <Button style={{
                    marginLeft: 'auto',
                    fontSize: '0.729vw',
                    backgroundColor: '#1F5B84',
                    color: '#FFFFFF',
                    width: '8.125vw',
                    height: '2.813vw'
                }}
                        onClick={handleSave}>
                    Save Changes
                </Button>
            )
        }
        return (
            <Grid item xs={12}>
                <div style={merge({display: 'flex', flexDirection: 'column'}, center)}>
                    {button()}
                    <Link style={{paddingTop: '0.521vw', color: '#717171', fontSize: '0.729vw', marginLeft: 'auto'}}
                          onClick={() => setView(!view)}
                          href={'#'}>
                        {linkText}
                    </Link>
                    <span>{error.message}</span>
                </div>
            </Grid>
        )
    }

    return (
        <div className='maintenance'
             style={merge({backgroundColor: '#FFFFFF', height: '800px', width: '1250px'}, center)}>
            <div style={{
                height: '4.06vw',
                borderBottom: '0.052vw solid #707070',
                display: 'flex',
                alignItems: 'center'
            }}>
                <span style={{fontSize: '1.25vw', fontWeight: 'Bold', lineHeight: '1.51vw', paddingLeft: '1.25vw'}}> Landing Maintenance Page </span>
            </div>
            <Grid container classes={{root: classes.landingPageBody}}>
                <Grid item xs={4}>
                    <div style={{fontSize: '0.625vw', color: '#0000008A', paddingBottom: '0.365vw'}}>
                        1st Section Image
                    </div>
                    <ImageUpload
                        name="firstSection"
                        value={landingPage.firstSection.image}
                        actionFn={handleImageUploadChange.bind(this)}
                        editable={!view}
                    />
                </Grid>
                <Grid item xs={8}>
                    <div>
                        <Input maxLength={100}
                               label={"First Section Tag Line"}
                               value={landingPage.firstSection.tagLine}
                               onChangeFn={onChangeInput.bind(this, 'firstSection', 'tagLine')}
                               readOnly={view}
                               additionalStyles={{root: classes.textfieldAdditionalStyle}}
                        />
                    </div>
                    {view && <div className={'spacer'} style={{marginBottom: 27}}/>}
                    <div>
                        <Input maxLength={200}
                               label="First Section Paragraph"
                               value={landingPage.firstSection.paragraph}
                               onChangeFn={onChangeInput.bind(this, 'firstSection', 'paragraph')}
                               readOnly={view}
                               rowsMax={3}
                               rows={3}
                               multiline
                        />
                    </div>
                </Grid>
                <Grid item xs={4} classes={{root: view ? classes.sectionSpacing : classes.smallSectionSpacing}}>
                    <div style={{fontSize: '0.625vw', color: '#0000008A', paddingBottom: '0.365vw'}}>
                        2nd Section Image
                    </div>
                    <ImageUpload
                        name="secondSection"
                        value={landingPage.secondSection.image}
                        actionFn={handleImageUploadChange.bind(this)}
                        editable={!view}
                    />
                </Grid>
                <Grid item xs={8} classes={{root: view ? classes.sectionSpacing : classes.smallSectionSpacing}}>
                    <div>
                        <Input maxLength={200}
                               label="Second Section Paragraph"
                               value={landingPage.secondSection.paragraph}
                               onChangeFn={onChangeInput.bind(this, 'secondSection', 'paragraph')}
                               readOnly={view}
                               rowsMax={3}
                               rows={3}
                               multiline
                        />
                    </div>
                </Grid>
                <Grid item xs={4} classes={{root: classes.sectionSpacing}}>
                    <div style={{fontSize: '0.625vw', color: '#0000008A', paddingBottom: '0.365vw'}}>
                        3rd Section Image
                    </div>
                    <ImageUpload
                        name="thirdSection"
                        value={landingPage.thirdSection.image}
                        actionFn={handleImageUploadChange.bind(this)}
                        editable={!view}
                    />
                </Grid>
                <Grid item xs={8} classes={{root: classes.sectionSpacing}}>
                    <div>
                        <Input maxLength={200}
                               label="Third Section Paragraph"
                               value={landingPage.thirdSection.paragraph}
                               onChangeFn={onChangeInput.bind(this, 'thirdSection', 'paragraph')}
                               readOnly={view}
                               rowsMax={3}
                               rows={3}
                               multiline
                        />
                    </div>
                </Grid>
                {displayButtons()}
            </Grid>
        </div>
    )
}

export default LandingMaintenancePage
