import React, {useState} from 'react'
import Button from "@material-ui/core/Button"
import {useAppContext} from "../../context/AppContext"
import * as actionsType from "../../util/actionsType"
import {trackPromise} from "react-promise-tracker"
import {FIRESTORE_COLLECTION, MENU_MODAL_TYPE} from "../../util/constants"
import firebase from "../../firebase"
import useFetch from "../../hooks/useFetch"
import MenuModal from "../../components/modals/MenuModal"
import useModal from "../../hooks/useModal"
import moment from "moment";
import {useAuthContext} from "../../context/AuthContext";
import {useDialogContext} from "../../context/DialogContext";
import TableContainer from "@material-ui/core/TableContainer"
import {Table as TableMui} from "@material-ui/core"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import ProductDescriptionModal from "../../components/modals/ProductDescriptionModal"

const db = firebase.firestore()
const storage = firebase.storage()

const onAddCategoryProductClicked = (type, newModal, setMenuModal, menus) => {
    let menu = {
        type: type,
        menus: menus ,
        isNew: true,
        isEdit: false
    }
    setMenuModal(menu)
    newModal()
}

const onProductDescriptionClicked = (editModal, productDescription) => {
  editModal(productDescription)
}

const columns = [
  {
    id:'image',
    label: "Image",
    style: {width: '6%', fontSize:'20px', fontWeight: '500'},
    render: (image) => {
      return  <img alt={'pic'} src={image.imageUrl} style={{width: '72px', height: '45px'}}/>
    }
  },
  {
    id: 'product-menu-content',
    label: "Product Menu Content",
    style: {width: '22%', fontSize: '20px', fontWeight: '500'}
  },
  {
    id: 'product-menu-description',
    label: "Product Menu Description",
    style: {width: '22%', fontSize: '20px', fontWeight: '500'}
  },
  {id: 'row-options', label: '', style: {width:'50%'}}
]

const rowOptions = [{
  label: 'Edit',
  onClick: ()=>{}
}, {
  label: 'Remove',
  onClick: ()=>{},
  style: {color: '#841F1FDE'}
}]

function Menus({menus, newModal, setMenuModal}){

    const {state, dispatch} = useAppContext()

    const nestedMenus = (menus.nodes || []).map( (menu,indexChild) => {
        return <Menus key={indexChild + 'test'} menus={menu}  newModal={newModal} setMenuModal={setMenuModal} type="child" />
    })

    const removeFromTree = (node, name) => {
        if (node.menuId === name) {
            node = undefined
        } else {
            node.nodes.forEach((child, id) => {
                if (!removeFromTree(child, name)){
                    node.nodes.splice(id, 1)
                }
            })
        }
        return node
    }

    const onRemoveClicked = (menu) => {
        let newMenus = state.menus
        newMenus.map( (menus, index) => {

            if(menu.depth === 0 && menus.menuId === menu.menuId) {
                //deleting first level
                newMenus.splice(index, 1)
                return

            }else{
                removeFromTree(menus, menu.menuId)
            }

        })

        dispatch({type: actionsType.SET_MENUS, payload: newMenus})
    }

    const onEditClicked = (menu) => {
        let menuModal = {
            type: menu.linkURL ? MENU_MODAL_TYPE.PRODUCT : menu.depth === 0 ? MENU_MODAL_TYPE.CATEGORY : MENU_MODAL_TYPE.SUB_CATEGORY,
            menus: menu || {},
            isNew: false,
            isEdit: true
        }
        setMenuModal(menuModal)
        newModal()
    }

    const buttonStyle = {
      fontSize: 20, fontWeight:'bold'
    }

    const trimMenuCategoryDescription = (description) => {
      if(!!!description) {
        return
      }
      const splittedHtml = description.split('<p><br></p>')
      const firstParagraph = splittedHtml[0]
      return firstParagraph.replace('<p>', '<p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">')
    }

    return (
      <>
        <TableRow style={{display:'flex'}}>
            <TableCell style={{display:'inline-flex', width:'6%'}}>
                { menus.depth === 0  &&
                    <img src={menus.image.imageUrl ||  menus.image?.fileToUpload} style={{
                        top: "308px",
                        left: "321px",
                        width: "73px",
                        height: "47px",
                        background: "#8B98C2 0% 0% no-repeat padding-box",
                        border: "1px solid #707070",
                        opacity: "1"
                    }}/>
                }
            </TableCell>
            <TableCell style={{display:'inline-flex', width:'22%', alignItems:'center'}}>
              <div style={{fontSize:"20px"}}>
                {menus.linkURL ?
                  <span>{`└${'─'.repeat(menus.depth)}■ `}
                    <span style={{fontStyle:"italic"}}>{menus.name}</span>
                  </span> :
                  <span>{!!!menus.depth ? '' : `└${'─'.repeat(menus.depth)} `}
                    <span style={{fontWeight: menus.depth === 0 ? 'bold' : ''}}>{menus.name}</span>
                  </span>
                }
              </div>
            </TableCell>
            <TableCell style={{display:'inline-flex', width:'20%', alignItems:'center'}}>
              <div style={{
                            fontSize:"20px", 
                            maxWidth: '100%'
              }}>
                {menus.depth === 0 &&
                  <div dangerouslySetInnerHTML={{__html: trimMenuCategoryDescription(menus.description)}} />                
                }
              </div>
            </TableCell>
            <TableCell style={{display:'inline-flex', width:'50%'}} align={'right'}> {/* note: align=right implements row-reverse */}
              <Button style={{...buttonStyle, color:'#841F1FDE'}} onClick={onRemoveClicked.bind(this, menus)}>Remove</Button>
              <Button style={{...buttonStyle}} onClick={onEditClicked.bind(this, menus)}>Edit</Button>
                {!menus.linkURL &&
                <>
                  <Button style={{...buttonStyle}} onClick={
                    onAddCategoryProductClicked.bind(this, MENU_MODAL_TYPE.PRODUCT, newModal, setMenuModal, menus)}
                  >Add Product</Button>
                  <Button style={{...buttonStyle}} onClick={
                    onAddCategoryProductClicked.bind(this, MENU_MODAL_TYPE.SUB_CATEGORY, newModal, setMenuModal, menus)}
                  >Add Sub-Category</Button>
                  {/* TODO: insert view here*/}
                </>
                }
            </TableCell>
        </TableRow>
        {nestedMenus}
        </>


)
}

function MenuMaintenancePage() {
    const {state} = useAppContext()
    const {showAlert} = useDialogContext()
    const {fetchMenus} = useFetch(FIRESTORE_COLLECTION.MENUS)
    const {fetchProductDescription} = useFetch(FIRESTORE_COLLECTION.PRODUCT_DESCRIPTION)
    const [menuModal, setMenuModal] = useState({menus: {}, isNew: true, type:'', isEdit: false})
    const {isOpen, newModal, modal} = useModal({onCloseFn: () => {}})
    const {isOpen: isOpenProductDesc, editModal: editModalProductDesc, modal: modalProductDesc} = useModal({onCloseFn: () => trackPromise(fetchProductDescription())})
    const {currentUser} = useAuthContext()
    const [error, setError] = useState({})

    useFetch(FIRESTORE_COLLECTION.PRODUCTS)

    const checkIfHasFileToUpload = (object) => {
        return !!object.image && object.image.hasOwnProperty("fileToUpload") && object.image.fileToUpload.indexOf('base64')
    }

    const cleanBase64ForFirebase = (fileToUpload) => {
        return fileToUpload.substr( fileToUpload.indexOf('base64') + 7)
    }

    const getItemsToUpload = () => {
        let items = []

        state.menus.forEach(menus => {
            if (checkIfHasFileToUpload(menus)) {
                items.push({
                    child: "images/" + menus.image.storageFileName,
                    fileToUpload: cleanBase64ForFirebase(menus.image.fileToUpload)
                })
            }
        })
        return items
    }

    const displayAlert = (success, error) => {
        showAlert({
            type: success ? 'success' : 'error',
            title: 'Save Success',
            message: success ? 'Successfully saved' :  error.message
        })
        trackPromise(fetchMenus())
    }

    const saveFileToStorage = (item) => {
        return storage.ref().child(item.child).putString(item.fileToUpload, "base64")
            .then(async (snapshot)  => {
                item.imageUrl = await snapshot.ref.getDownloadURL()
            }).catch(error => console.log('ERROR UPLOADING TO STORAGE', item, error))
    }

    const cleanData = (uploadItems) => {
        //remove all fileToUpload
        let menus = JSON.parse(JSON.stringify(state.menus))
        //downloadURL right away so that it will not call storage always
        if(uploadItems.length > 0) {
            menus.forEach( menu => {
                if(!!menu.image) {
                    const item = uploadItems.filter( item => item.child.replace("images/","") === menu.image.storageFileName )
                    if(!!item.length) {
                        menu.image['imageUrl'] = item[0].imageUrl
                    }
                    delete menu.image.fileToUpload
                }
            })
        }

        return menus
    }

    const onSave = () => {
        // DB saving
        let uploadItems = getItemsToUpload()
        //if nothing to upload, it will automatically go to success callback
        Promise.all(
            uploadItems.map(item => saveFileToStorage(item))
        ).then(() => {
            let data = cleanData(uploadItems)

            //New Menu
            data.createdAt = moment(new Date()).format('MM/DD/YYYY hh:mm A')
            data.createdBy = currentUser.email

            trackPromise(
                db.collection(FIRESTORE_COLLECTION.MENUS)
                    .doc('menus')
                    .set({text: JSON.stringify(data)})
                    .catch((error) => setError({message: error}))
            )

        })
            .then(() => displayAlert(true))
            .catch(e => displayAlert(false, e))

    }

    return (
        <div style={{backgroundColor: '#FFFFFF', height: '100%', width: '100%'}}>
            {isOpen && <MenuModal {...modal} isNew={menuModal.isNew} isEdit={menuModal.isEdit} menus={menuModal.menus} type={menuModal.type}/>}
            {isOpenProductDesc && <ProductDescriptionModal {...modalProductDesc} />}
          <div style={{height: '80px', borderBottom: '1px solid #707070', display: 'flex', alignItems: 'center'}}>
            <span style={{fontSize: '24px', lineHeight: '29px', paddingLeft: '25px', fontWeight:'bold'}}> Product Menu Maintenance </span>
          </div>
            <div style={{padding:'20px 0px 5px', borderBottom: '1px solid #707070'}}>
              <div style={{display:'flex', marginRight: '45px'}}>
                  <Button style={{
                      display:'flex',
                      margin: '0 10px 0px auto',
                      backgroundColor: '#1F5B84',
                      color: '#FFFFFF',
                      boxShadow: '0px 2px 2px #0000003D',
                      borderRadius: '2px',
                      fontSize: '14px',
                      textTransform: 'none',
                      width: '230px',
                      height: '36px'
                  }}
                          onClick={onProductDescriptionClicked.bind(this, editModalProductDesc, state.productDescription)}>View Product Page Description
                  </Button>
                  <Button style={{
                    display:'flex',
                    marginRight: '10px',
                    backgroundColor: '#1F5B84',
                    color: '#FFFFFF',
                    boxShadow: '0px 2px 2px #0000003D',
                    borderRadius: '2px',
                    fontSize: '14px',
                    textTransform: 'none',
                    width: '160px',
                    height: '36px'
                  }}
                          onClick={onAddCategoryProductClicked.bind(this, MENU_MODAL_TYPE.CATEGORY, newModal, setMenuModal, null)}>Add Category
                  </Button>
                  <Button style={{
                      backgroundColor: '#1F8481',
                      color: '#FFFFFF',
                      boxShadow: '0px 2px 2px #0000003D',
                      borderRadius: '2px',
                      fontSize: '14px',
                      textTransform: 'none',
                      width: '160px',
                      height: '36px'
                  }}
                          onClick={onAddCategoryProductClicked.bind(this, MENU_MODAL_TYPE.PRODUCT, newModal, setMenuModal, null)}>Add Product
                  </Button>
              </div>
              <div style={{margin:'0px 25px', color: '#808080', fontWeight:'600', fontFamily: 'Roboto'}}>Note: Bold Text = Main Category, └ Normal Text = Sub Category, ■ Italic Text = Product</div>
            </div>

            <div>
              <TableContainer style={{height: 800}} component={'div'}>
                <TableMui stickyHeader style={{tableLayout: 'fixed'}}>

                  <TableHead>
                    <TableRow style={{display:'flex'}}>
                      {columns.map(column =>
                        <TableCell id={column.id} style={{display:'inline-flex', ...column.style}}>
                          {column.label}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {state.menus.map( (menu, index) => <Menus menus={menu} newModal={newModal} setMenuModal={setMenuModal}/>)}
                  </TableBody>

                </TableMui>
              </TableContainer>
              <div style={{height: '60px', margin:'10px'}}>
                <Button variant="contained" style={{
                  width: '200px',
                  height: '50px',
                  float: 'right',
                  backgroundColor: '#47CDC6',
                  color: '#FFFFFF'
                }} onClick={onSave.bind(this)}>SAVE</Button>
                <span>{error.message}</span>
              </div>
            </div>
        </div>
    )
}

export default MenuMaintenancePage