import * as actionsType from "./util/actionsType";

export function appReducer(state, action){
    console.log(action.type, action.payload)
    switch (action.type) {
        //COMMON REDUCERS - CLIENT and ADMIN
        case actionsType.SET_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }
        case actionsType.SET_ABOUT_US:
            return {
                ...state,
                aboutUs: action.payload
            }
        case actionsType.SET_CONTACT_US:
            return {
                ...state,
                contactUs: action.payload
            }
        case actionsType.SET_CAREERS:
            return {
                ...state,
                careers: action.payload
            }
        case actionsType.SET_USERS:
            return {
                ...state,
                users: action.payload
            }
        case actionsType.SET_FAQS:
            return {
                ...state,
                faqs: action.payload
            }
        case actionsType.SET_NEWS:
            return {
                ...state,
                news: action.payload
            }
        case actionsType.SET_PRODUCT_DESCRIPTION:
            return {
                ...state,
                productDescription: action.payload
            }
        case actionsType.SET_CONFIRM_DIALOG:
            return {
                ...state,
                confirmDialog: action.payload
            }
        case actionsType.CLOSE_CONFIRM_DIALOG:
            return {
                ...state,
                confirmDialog: {
                    ...state.confirmDialog,
                    open: false
                }
            }
        case actionsType.SET_LANDING_PAGE:
            return {
                ...state,
                landingPage: action.payload
            }
        case actionsType.SET_MENUS:
            return {
                ...state,
                menus: action.payload
            }


         //CLIENT SIDE REDUCERS
        case actionsType.TOGGLE_NAV_DRAWER:
            return {
                ...state,
                navDrawerOpen: action.payload
            }
        case actionsType.TOGGLE_NAV_COLLAPSE_PRODUCT:
            return {
                ...state,
                navCollapseProduct: action.payload
            }
        case actionsType.SHOW_PRODUCT_MENU_MODAL:
            return {
                ...state,
                showProductMenuModal: action.payload
            }
        case actionsType.SHOW_PRODUCT_CAROUSEL_MODAL:
            return {
                ...state,
                showProductCarouselModal: action.payload
            }
        case actionsType.SET_PRODUCT_CAROUSEL_MODAL_ITEM:
            return {
                ...state,
                productCarouselModalItem: action.payload
            }
        case actionsType.SET_BANNER_VIDEO_URL:
            return {
                ...state,
                bannerVideoUrl: action.payload
            }
        default:
            console.error("NO ACTION TYPE FOR "+ action.type +" YET")
            throw new Error()
    }
}
