import React, {useState} from 'react'
import Grid from "@material-ui/core/Grid"
import Footer from "../components/Footer"
import {Link} from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import {useAppContext} from "../context/AppContext"
import Paper from "@material-ui/core/Paper"
import "../styles/CareerPage.scss"
import useWindowSize from "../hooks/useWindowSize"


function CareerPage() {
    const {state} = useAppContext()
    const [job, setJob] = useState({})
    const windowSize = useWindowSize()
    const showJobDescription = (career) => {
        setJob({title: career.title, description: career.description})
    }

  function getHowToApplySection() {
    return <>
      <div className={'section-header'}>How to apply?</div>
      <div className={'section-content'}>
        If you want to join the cause, kindly e-mail your application and detailed resume to&nbsp;
        <span style={{textDecoration: 'underline'}}>consolidatedadhesives@gmail.com</span>
      </div>
    </>
  }

  return (
        <div id={'career-page'}>
            <Grid container>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div className={'breadcrumb-container'}>
                    <Breadcrumbs className={'breadcrumbs'}>
                      <Link to="/">{`< Back to Home`}</Link>
                      <Typography className={'breadcrumbs'}>Careers</Typography>
                    </Breadcrumbs>
                  </div>
                    <div className='careers-container'>
                        <div className='title'>Work With Us!</div>
                        <div className='section-content'>
                            Join the company that cares. Leading the water-based adhesives industry for more than 30
                            years with consistently high-quality products.
                        </div>
                        <div className={'section-header'}>Career Opportunities</div>
                        <div className={'jobs-container'}>
                            {state.careers.filter(career => career.active).map((career, index) => {
                                return <div key={index} className='job-title-text'>
                                  <a href='#' onClick={showJobDescription.bind(this, career)}> {career.title}</a>
                                </div>
                            })}
                        </div>
                      {windowSize.width > 425 ? getHowToApplySection() : null  }
                    </div>
                </Grid>
                <Grid id={'career-display-container'} item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <div className={'career-display'}/>
                    {job.title && <Paper className={'career-display-no-img'} style={{

                    }}>
                        <div className={'job-title'}>{job.title}</div>
                        <div className={'job-description'}>{job.description}</div>
                    </Paper>
                    }
                    <img className='careers-bg'/>

                  {windowSize.width > 425 ? null :
                    getHowToApplySection()
                  }

                </Grid>

            </Grid>
          <Footer/>
        </div>
    )
}

export default CareerPage
