import React, {useState} from 'react'
import Grid from "@material-ui/core/Grid"
import Footer from "../components/Footer"
import Container from "@material-ui/core/Container"
import Input from "../components/Input"
import Button from "@material-ui/core/Button"
import MapIcon from '@material-ui/icons/Map'
import CallEndIcon from '@material-ui/icons/CallEnd'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import EmailIcon from '@material-ui/icons/Email'
import {Link} from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import firebase from "../firebase"
import {useDialogContext} from "../context/DialogContext"
import {useAppContext} from "../context/AppContext"
import {trackPromise} from "react-promise-tracker"
import '../styles/ContactUsPage.scss'


const emailInitialState = {emailAddress: "", name: "", subject: "", message: ""}

const contactDetails = [
  {
    icon: <MapIcon className='contact-icon'/>,
    label: 'CONSOLIDATED ADHESIVES INC.'
  },
  {
    icon: <CallEndIcon className='contact-icon'/>,
    label: 'Tel. No.:'
  },
  {
    icon: <LibraryBooksIcon className='contact-icon'/>,
    label: 'Fax. No.:'
  },
  {
    icon: <EmailIcon className='contact-icon'/>,
    label: 'Email:'
  }
]

function ContactUsPage() {
    const [email, setEmail] = useState(emailInitialState)
    const {showAlert} = useDialogContext()
    const {state} = useAppContext()

    const onChangeInput = (field, event) => {
        const value = event.target.value
        setEmail({
            ...email,
            [field]: value
        })
    }

    const sendEmail = () => {
        let sendEmail = firebase.functions().httpsCallable('app/sendMail', {})
        trackPromise(
            sendEmail(email).then(function (result) {
                showAlert({
                    type: 'success',
                    title: 'Email Sent',
                    message: 'Email sent successful!'
                })
                setEmail(emailInitialState)
            }).catch(function (error) {
                showAlert({
                    type: 'error',
                    title: 'Email Sending Failed',
                    message: 'An error was encountered. Please try again or send us an email using your account.'
                })
            })
        )
    }

    return (
        <div id={'contact-us'}>
            <Grid container>
                <Grid item xs={12} sm={12} xl={7} lg={7} className={'form-container'}>
                    <div className='breadcrumb-container'>
                      <Breadcrumbs className={'breadcrumbs'}>
                        <Link to="/">{`< Back to Home`}</Link>
                        <Typography className={'breadcrumbs'}>Contact Us</Typography>
                      </Breadcrumbs>
                    </div>
                  <div className={'title-mobile'}>
                    Get In Touch
                  </div>
                    <div className={'form-section'}>
                        <div className={'content'}>
                            <div className={'title'}>
                              Get In Touch
                            </div>
                            <Input label='Email'
                                   maxLength={50}
                                   value={email.emailAddress}
                                   onChangeFn={onChangeInput.bind(this, "emailAddress")}
                                   fullWidth
                            />
                            <Input label='Name'
                                   maxLength={50}
                                   value={email.name}
                                   onChangeFn={onChangeInput.bind(this, "name")}
                                   fullWidth
                            />
                            <Input label='Subject'
                                   maxLength={50}
                                   value={email.subject}
                                   onChangeFn={onChangeInput.bind(this, "subject")}
                                   fullWidth
                            />
                            <Input label='Message'
                                   maxLength={120}
                                   value={email.message}
                                   rows={3}
                                   rowsMax={3}
                                   onChangeFn={onChangeInput.bind(this, "message")}
                                   multiline
                                   fullWidth
                            />
                          <Button className={'send-button'}
                                  variant="contained"
                                  onClick={sendEmail}>Send</Button>
                        </div>
                    </div>
                    <div className={'bg-leaves'} />
                </Grid>
                <Grid item xs={12} sm={12} xl={5} lg={5} className={'contact-details-root'}>
                    <Container className={'contact-details-container'}>
                        <div className={'title'}>
                            Other ways to connect
                        </div>

                        <Grid container>
                            {contactDetails.map((item, index) => (
                                <Grid container key={item + index} className={'detail'}>
                                  <Grid item xs={2} sm={2} xl={1} lg={1}> {item.icon} </Grid>
                                    <Grid item xs={10} sm={2} xl={11} lg={11}>
                                        <span className={'bold-text'}>{item.label} </span>
                                        <span className={'sub-text'}>
                                            {index === 0 && (state.contactUs?.address || '139-D Aurora Blvd. San Juan 1500 Metro Manila Philippines')}
                                            {index === 1 && (state.contactUs?.telephone || '+(632)872-8797, +(632)874-1511')}
                                            {index === 2 && (state.contactUs?.fax || '+(632)874-1486')}
                                            {index === 3 && (state.contactUs?.email || 'consolidatedadhesives@gmail.com')}
                                        </span>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
          <Footer/>
        </div>
    )
}

export default ContactUsPage
