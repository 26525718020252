import React, {createContext, useContext, useReducer} from "react"
import {appReducer} from "../reducer"

export const AppContext = createContext()

const initialState = {
    products: [],
    news: [],
    users: [],
    faqs: [],
    careers: [],
    menus: [],
    aboutUs: {},
    productCarouselModalItem: {},
    navDrawerOpen: false,
    navCollapseProduct: false,
    showProductMenuModal: false,
    showProductCarouselModal: false,
    productDescription: {}
}

export const useAppContext = () => {
    const {state, dispatch} = useContext(AppContext)
    return {state, dispatch}
}

export const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(appReducer, initialState)

    return (
        <AppContext.Provider
            value={{state, dispatch}}
        >
            {children}
        </AppContext.Provider>
    )

}
