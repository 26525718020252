import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles/index"
import Modal from "@material-ui/core/Modal/index"
import Button from "@material-ui/core/Button/index"
import Link from "@material-ui/core/Link/index"
import Grid from "@material-ui/core/Grid/index"
import firebase from "../../firebase"
import Checkbox from "@material-ui/core/Checkbox"
import {useDialogContext} from "../../context/DialogContext"
import Input from "../Input"
import {trackPromise} from "react-promise-tracker"

const db = firebase.firestore()

const useStyles = makeStyles(theme => ({
    paper: {
        width: 591,
        backgroundColor: theme.palette.background.paper,
        margin: 'auto'
    },
    titleStyle: {
        padding: '1.042vw 0 1.042vw 1.042vw',
        borderBottom: '1px solid #707070'
    },
    bodyStyle: {
        padding: '1.823vw 2.865vw 0 2.865vw'
    },
    buttonContainerStyle: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-end',
        margin: '40px 50px'
    },
    modalButtonStyle: {
        backgroundColor: '#1F5B84',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalButtonViewStyle: {
        backgroundColor: '#8A8A8A',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalLinkStyle: {
        color: '#717171',
        textDecoration: 'underline',
        fontSize: '0.729vw',
        fontWeight: 550
    }
}))

function CareerModal(props) {
    const {showConfirmDialog, showAlert} = useDialogContext()
    const classes = useStyles()
    const [career, setCareer] = useState({title: '', description: '', active: false})
    const [error, setError] = useState({})
    const [view, setView] = useState(false)

    useEffect(() => {
        props.data.id && setCareer(props.data)
        setView(props.view)
    }, [props.data])

    const onChangeInput = (field, event) => {
        const value = (field === 'active') ? event.target.checked : event.target.value
        setCareer({
            ...career,
            [field]: value
        })
    }

    const save = (event) => {
        const addEdit = career.id ? 'edit' : 'add'
        showConfirmDialog({
            title: `${career.id ? 'Edit' : 'Add'} Career`,
            message: 'Are you sure you want to ' + addEdit + ' career ' + career.title + '?',
            confirmFn: () => {
                onSubmit(event)
                props.closeFn()
            },
            type: addEdit
        })
    }

    const onSubmit = event => {
        event.preventDefault()
        // DB saving
        props.data.id
            ? trackPromise(db.collection('careers').doc(career.id).set(career).then(() => showAlert({
                type: 'success',
                title: 'Edit Success',
                message: 'Success updating Career'
            })).catch((error) => {
                setError({message: error})
                showAlert({type: 'error', title: 'Update Error', message: 'Error updating Career'})
            }))
            : trackPromise(db.collection('careers').add(career).then(() => showAlert({
                type: 'success',
                title: 'Save Success',
                message: 'Success saving Career'
            })).catch((error) => {
                setError({message: error})
                showAlert({type: 'error', title: 'Add Error', message: 'Error adding Career'})
            }))
    }

    const handleModalCancel = (event) => {
        event.preventDefault()
        props.closeFn()
    }

    const displayModalHeader = () => {
        let modalTitle = career.id ? 'Edit Career' : 'New Career'
        if (view) {
            modalTitle = 'View Career'
        }

        return (
            <Grid container item xs={12} className={classes.titleStyle}>
                <div style={{alignItems: 'center', display: 'flex'}}>
                    <span style={{fontSize: '1.250vw', fontWeight: 'bold'}}>
                        {modalTitle}
                    </span>
                </div>
            </Grid>
        )
    }

    const displayModalBody = () => {
        return (
            <Grid item xs={12} className={classes.bodyStyle}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                    <Input
                        maxLength={50}
                        label={'Career Name'}
                        onChangeFn={onChangeInput.bind(this, 'title')}
                        value={career.title}
                        error={career.title.length === 0}
                        readOnly={view}
                        fullWidth
                    />
                    <div style={{paddingLeft: ' 4.844vw', display: 'flex', alignItems: 'center'}}>
                        <label style={{fontSize: 12, color: '#0000008A'}}>Active</label>
                        <Checkbox
                            name={'active'}
                            checked={career.active}
                            onChange={onChangeInput.bind(this, 'active')}
                            size={'small'}
                            color={'default'}
                            disabled={view}
                        />
                    </div>
                </div>
                <div>
                    <Input
                        maxLength={1000}
                        label={'Career Description'}
                        onChangeFn={onChangeInput.bind(this, 'description')}
                        value={career.description}
                        error={career.description.length === 0}
                        readOnly={view}
                        rows={17}
                        multiline
                        fullWidth
                    />
                </div>
            </Grid>
        )
    }

    const displayModalButtons = () => {
        const buttonAction = (event) => {
            view ? handleModalCancel(event) : save(event)
        }

        const linkAction = (event) => {
            view ? setView(false) : handleModalCancel(event)
        }

        return (
            <Grid item xs={12} className={classes.buttonContainerStyle}>
                <div style={{paddingBottom: '0.677vw'}}>
                    <Button variant="contained" size="large"
                            className={view ? classes.modalButtonViewStyle : classes.modalButtonStyle}
                            onClick={buttonAction.bind(this)}>
                        {view ? 'Close' : career.id ? 'Save Changes' : 'Add Career'}
                    </Button>
                </div>
                <div>
                    <Link className={classes.modalLinkStyle}
                          href='#' onClick={linkAction.bind(this)}>{view ? 'Edit career' : 'Cancel'}</Link>
                </div>
            </Grid>
        )
    }

    return (
        <div id={'careerModal'}>
            {error.message}
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={'modal'}
                style={{display: 'flex'}}
                open
            >
                <div className={classes.paper} classes={{modal: classes.modalStyle}}>
                    <Grid container>
                        {displayModalHeader()}
                        {displayModalBody()}
                        {displayModalButtons()}
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

export default CareerModal
