export const FIRESTORE_COLLECTION = {
    PRODUCTS : "products",
    MENUS: "menus",
    ABOUT_US : "aboutUs",
    FAQS : "faqs",
    CAREERS : "careers",
    CONTACT_US : "contactUs",
    LANDING_PAGE : "landingPage",
    USERS : "users",
    NEWS : "news",
    PRODUCT_DESCRIPTION : "productDescription",
    ALL : "all"
}

export const ALERT_TYPE = {
    ERROR : "error",
    SUCCESS: "success"
}

export const MENU_MODAL_TYPE = {
    CATEGORY: "category",
    SUB_CATEGORY: "subCategory",
    PRODUCT: "product"
}

export const URL_REGEX_PATTERN = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
