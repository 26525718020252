import React from 'react'
import HomePage from "./pages/HomePage"
import {Route, Switch} from "react-router-dom"
import ProductsPage from "./pages/ProductsPage"
import CareerPage from "./pages/CareerPage"
import Loading from "./components/Loading"
import MenuIcon from '@material-ui/icons/Menu'
import NavDrawer from "./components/NavDrawer"
import AboutUsPage from "./pages/AboutUsPage"
import ContactUsPage from "./pages/ContactUsPage"
import * as actionsType from "./util/actionsType"
import FaqsPage from './pages/FaqsPage'
import {useAppContext} from "./context/AppContext"
import useFetch from "./hooks/useFetch"
import {FIRESTORE_COLLECTION} from "./util/constants"
import NotFoundPage from "./pages/NotFoundPage"
import NavBar from "./components/NavBar";
import useWindowSize from "./hooks/useWindowSize";
import MenuProductsPage from "./pages/MenuProductsPage";
import Image from "./components/Image"

function App() {
    const {dispatch} = useAppContext()
    const windowsSize = useWindowSize()

    const toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        dispatchDrawer(open)
    }

    const dispatchDrawer = (data) => {
        dispatch({type: actionsType.TOGGLE_NAV_DRAWER, payload: data})
    }

    useFetch(FIRESTORE_COLLECTION.ALL)

    return (
        <div className='root'>
            <Loading/>
          {/*<MenuIcon*/}
          {/*  style={{*/}
          {/*    position: "absolute",*/}
          {/*    backgroundColor: '#7A7A7A',*/}
          {/*    width: "50px",*/}
          {/*    height: "50px",*/}
          {/*    color: '#FFFFFF',*/}
          {/*    zIndex: 11,*/}
          {/*    cursor: 'pointer'*/}
          {/*  }}*/}
          {/*  onClick={() => dispatchDrawer(true)}/>*/}
            {windowsSize.width > 800 ? <NavBar/> :
                <div className={'nav-bar-mobile'}>
                  <MenuIcon className={'menu-icon-mobile'}
                    onClick={() => dispatchDrawer(true)}/>
                  <span style={{marginLeft: 10, fontSize: 13}}>Menu</span>

                  <Image className={'stikwel-icon-mobile'}
                         alt={'stikwel-icon'}
                         src={'/images/stikwel-silver-2.svg'}/>
                </div>
            }
            <NavDrawer toggleDrawer={toggleDrawer}/>

            <Switch>
                <Route exact path="/products/:productId" component={ProductsPage}/>
                <Route exact path="/menu-products" component={MenuProductsPage}/>
                <Route exact path="/about" component={AboutUsPage}/>
                <Route exact path="/careers" component={CareerPage}/>
                <Route exact path="/contact" component={ContactUsPage}/>
                <Route exact path="/faq/:productId?" component={FaqsPage}/>
                <Route exact path="/" component={HomePage}/>
                <Route component={NotFoundPage}/>
            </Switch>
        </div>)
}

export default App
