import React from 'react'
import Paper from "@material-ui/core/Paper"
import moment from "moment"

function NewsCarouselItem(props) {
    const date = props.item.createdAt
        ? moment(props.item.createdAt).format('MMMM DD, YYYY') + '\n'
        : ''
    const title = props.item.title ? props.item.title + '\n' : ''
    let url = props.item.url ? props.item.url : ''
    if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }

    return (
        <Paper className='news-item' onClick={() => window.open(url, "_blank")} style={{
            backgroundImage: `url(${props.item.image.imageUrl})`,
        }}>
          <div className='news-item-text'>
            {date}
            {title}
            {url}
          </div>
        </Paper>
    )
}

NewsCarouselItem.propTypes = {}

export default NewsCarouselItem
