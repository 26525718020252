import React from 'react'
import {ControlledMenu , MenuItem, SubMenu} from "@szhsin/react-menu"

function ProductMenu(props) {

    const getNavMenuItems = (menus) => {
        return menus.map(subItem => {
            if (subItem.nodes.length > 0) {
                return <SubMenu offsetX={3} itemClassName={({hover}) => hover ? 'product-item-hover' : 'product-item'}
                                label={subItem.name}>
                    {getCarouselMenuItems(subItem)}
                </SubMenu>
            }
            return <MenuItem disabled={!!!subItem.linkURL} className={
                ({hover}) => hover ? 'product-item-hover' : 'product-item'}
                             href={"/products/" + subItem.linkURL}>
                {subItem.name}
            </MenuItem>
        })
    }

    const getCarouselMenuItems = (menu) => {
        let menuTree = []
        if(menu && menu.nodes) {
            menuTree = menu.nodes.map(subItem => {
                if(subItem.nodes.length > 0){
                    return <SubMenu offsetX={3} itemClassName={({hover})=> hover ? 'product-item-hover' : 'product-item'}
                                    label={subItem.name}>
                        {getCarouselMenuItems(subItem)}
                    </SubMenu>
                }
                return <MenuItem disabled={!!!subItem.linkURL} className={
                    ({ hover }) => hover ? 'product-item-hover': 'product-item'}
                                 href={"/products/" + subItem.linkURL}>
                    {subItem.name}
                </MenuItem>
            })
            return menuTree
        }
    }

    return (
        <ControlledMenu {...props}>
            {props.isNav ? getNavMenuItems(props.menus) : getCarouselMenuItems(props.menus )}
        </ControlledMenu>
    )
}

export default ProductMenu