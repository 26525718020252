import React, {useEffect, useState} from 'react'
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import useFetch from "../../hooks/useFetch"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import {useAppContext} from "../../context/AppContext"
import firebase from "../../firebase"
import {useDialogContext} from "../../context/DialogContext"
import Input from "../../components/Input"
import {trackPromise} from "react-promise-tracker";

const db = firebase.firestore()

function ContactUsMaintenancePage() {
    const {state} = useAppContext()
    const {showConfirmDialog, showAlert} = useDialogContext()
    const [contactUs, setContactUs] = useState({address: '', telephone: '', fax: '', email: ''})
    const [error, setError] = useState({})
    const [view, setView] = useState(true)

    useFetch(FIRESTORE_COLLECTION.CONTACT_US)

    useEffect(() => {
        state.contactUs && setContactUs(state.contactUs)
    }, [state.contactUs])

    const onChangeInput = (field, event) => {
        const value = event.target.value
        setContactUs({
            ...contactUs,
            [field]: value
        })
    }

    const handleSave = (event) => {
        showConfirmDialog({
            title: "Update Contacts",
            message: 'Are you sure you want to update contacts?',
            confirmFn: () => {
                onSubmit(event)
                setView(true)
            },
            type:'edit'
        })
    }


    const onSubmit = event => {
        //this logic will change after we finalize the cost efficient query
        event.preventDefault()
        // DB saving
        trackPromise(
            Promise.all(
                Object.keys(contactUs).map(key => saveToDB(key))
            ).then(() => showAlert({
                type: 'success',
                title: 'Update Success',
                message: 'Success updating Contact Us details'
            }))
        )
    }

    const saveToDB = (key) => {
        return db.collection(FIRESTORE_COLLECTION.CONTACT_US).doc(key)
            .set({displayText: contactUs[key]})
            .catch((error) => setError({message: error}))
    }

    const maintenanceButtons = () => {
        const linkText = view ? 'Edit Content' : 'Cancel'
        const button = () => {
            if(view) {
                return
            }
            return (
                <Button style={{
                    alignSelf:'flex-end',
                    fontSize: 14,
                    backgroundColor: '#1F5B84',
                    color: '#FFFFFF',
                    width: 156,
                    height: 54
                }}
                        onClick={handleSave}>
                    Save Changes
                </Button>
            )
        }

        return (
            <div style={{margin:'60px 57px 0px', display:'flex', flexDirection:'column'}}>
                {button()}
                <Link style={{alignSelf:'flex-end', paddingTop: 10, color: '#717171', fontSize: 14}}
                      onClick={() => setView(!view)}
                      href={'#'}>
                    {linkText}
                </Link>
                <span>{error.message}</span>
            </div>
        )
    }

    return (
        <div style={{backgroundColor: '#FFFFFF', height: 732, width: 800, margin:'auto'}} className={'maintenance'}>
            <div style={{height: 78, borderBottom: '1px solid #707070', display: 'flex', alignItems: 'center'}}>
                <span style={{fontSize: 24, fontWeight: 'Bold', paddingLeft: 24}}>
                    Contact Us Maintenance
                </span>
            </div>
            <div style={{padding: '54px 57px 0px'}}>
                <Input maxLength={200}
                       label={"Address"}
                       value={contactUs.address}
                       onChangeFn={onChangeInput.bind(this, 'address')}
                       rowsMax={3}
                       rows={1}
                       multiline
                       readOnly={view}
                       fullWidth
                />
                {view && <div style={{paddingBottom: 27}}/>}
                <Input maxLength={100}
                       label={"Telephone Number"}
                       value={contactUs.telephone}
                       onChangeFn={onChangeInput.bind(this, 'telephone')}
                       readOnly={view}
                       fullWidth
                />
                {view && <div style={{paddingBottom: 27}}/>}
                <Input maxLength={100}
                       label={"Fax Number"}
                       value={contactUs.fax}
                       onChangeFn={onChangeInput.bind(this, 'fax')}
                       readOnly={view}
                       fullWidth
                />
                {view && <div style={{paddingBottom: 27}}/>}
                <Input maxLength={100}
                       label={"Email Address"}
                       value={contactUs.email}
                       onChangeFn={onChangeInput.bind(this, 'email')}
                       readOnly={view}
                       fullWidth
                />
            </div>
            {maintenanceButtons()}
        </div>
    )
}

export default ContactUsMaintenancePage