import React, {useEffect, useState} from 'react'
import {useAppContext} from "../../context/AppContext"
import useFetch from "../../hooks/useFetch"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import firebase from "../../firebase"
import {useDialogContext} from "../../context/DialogContext"
import ReactQuill from "react-quill"
import {trackPromise} from "react-promise-tracker"
import Grid from "@material-ui/core/Grid/index"

const db = firebase.firestore()

function AboutUsMaintenancePage() {
    const {state} = useAppContext()
    const {showConfirmDialog} = useDialogContext()
    const [aboutUsDetails, setAboutUsDetails] = useState({displayText: '', mission: '', vision: ''})
    const [error, setError] = useState({})
    const [view, setView] = useState(true)

    useFetch(FIRESTORE_COLLECTION.ABOUT_US)

    useEffect(() => {
        populateAboutUsDetails()
    }, [state.aboutUs])

    const getValidText = (text) => {
        return !!text ? text : ''
    }

    const populateAboutUsDetails = () => {
        setAboutUsDetails({
            displayText: getValidText(state.aboutUs.displayText),
            mission: getValidText(state.aboutUs.mission),
            vision: getValidText(state.aboutUs.vision)
        })
    }

    const handleSave = (event) => {
        showConfirmDialog({
            title: "Update About Us",
            message: 'Are you sure you want to update about us?',
            confirmFn: () => {
                onSubmit(event)
                setView(true)
            },
            type: 'edit'
        })
    }

    const onSubmit = event => {
        //this logic will change after we finalize the cost efficient query
        event.preventDefault()
        // DB saving
        trackPromise(
            db.collection(FIRESTORE_COLLECTION.ABOUT_US)
                .doc('content')
                .set(aboutUsDetails)
                .catch((error) => setError({message: error}))
        )
    }

    const onChangeInput = (field, value) => {
        // prevent proceeding to update state when value is changed
        // somehow onchange event is triggered when the value on quill is changed
        // e.g. initial value is '' then updated to 'actual value' <- this triggers onchange event
        if(view) {
            return
        }

        setAboutUsDetails({
            ...aboutUsDetails,
            [field]: value
        })
    }

    const aboutUsButtons = () => {
        const linkText = view ? 'Edit Content' : 'Cancel'
        const button = () => {
            if (view) {
                return
            }
            return (
                <Button style={{
                    marginLeft: 'auto',
                    fontSize: '0.729vw',
                    backgroundColor: '#1F5B84',
                    color: '#FFFFFF',
                    width: '8.125vw',
                    height: '2.813vw'
                }}
                        onClick={handleSave}>
                    Save Changes
                </Button>
            )
        }
        return (
            <div style={{display: 'flex', flexDirection: 'column', padding: '0px 55px', margin: '20px 0px 30px'}}>
                {button()}
                <Link style={{paddingTop: '0.521vw', color: '#717171', fontSize: '0.729vw', marginLeft: 'auto'}}
                      onClick={onLinkButtonClick.bind(this)}
                      href={'#'}>
                    {linkText}
                </Link>
                <span>{error.message}</span>
            </div>
        )
    }

    const onLinkButtonClick = () => {
        setView(!view)
        populateAboutUsDetails()
    }

    const displayAboutUsContent = () => {
        return (
            <div style={{margin: '2.083vw', height: '670px'}}>
                <div style={{fontSize: '12px', color: '#0000008A', marginBottom: '1vw'}}>
                    About Us Content
                </div>
                <ReactQuill style={{
                        border: view ? 'none' : '1px solid #ccc',
                        minHeight: '90%',
                        maxHeight: '90%',
                        overflowY: 'auto'
                    }}
                    value={aboutUsDetails.displayText}
                    defaultValue={''}
                    modules={{toolbar: view ? '' : ['bold', 'italic', 'underline']}}
                    onChange={onChangeInput.bind(this, 'displayText')} readOnly={view} placeholder={'Compose About Us Content...'}/>
            </div>
        )
    }

    const displayMissionAndVision = () => {
        return (
            <div style={{margin: '2.083vw'}}>
                <div style={{height: '325px'}}>
                    <div style={{fontSize: '12px', color: '#0000008A', marginBottom: '1vw'}}>
                            Mission
                    </div>
                    <ReactQuill style={{
                            border: view ? 'none' : '1px solid #ccc',
                            minHeight: '90%',
                            maxHeight: '90%',
                            overflowY: 'auto'
                        }}
                        value={aboutUsDetails.mission}
                        defaultValue={''}
                        modules={{toolbar: view ? '' : ['bold', 'italic', 'underline']}}
                        onChange={onChangeInput.bind(this, 'mission')} readOnly={view} placeholder={'Compose mission...'}/>
                </div>
                <div style={{marginTop: '1.042vw', height: '325px'}}>
                    <div style={{fontSize: '12px', color: '#0000008A', marginBottom: '1vw'}}>
                            Vision
                    </div>
                    <ReactQuill style={{
                            border: view ? 'none' : '1px solid #ccc',
                            minHeight: '90%',
                            maxHeight: '90%',
                            overflowY: 'auto'
                        }}
                        value={aboutUsDetails.vision}
                        defaultValue={''}
                        modules={{toolbar: view ? '' : ['bold', 'italic', 'underline']}}
                        onChange={onChangeInput.bind(this, 'vision')} readOnly={view} placeholder={'Compose vision...'}/>
                </div>
            </div>
        )
    }

    return (
        <div style={{
            backgroundColor: '#FFFFFF',
            width: '1400px',
            margin: 'auto'
        }}>
            <div style={{height: '4.06vw', borderBottom: '1px solid #707070', display: 'flex', alignItems: 'center'}}>
                <span style={{fontSize: '1.25vw', fontWeight: 'Bold', lineHeight: '1.51vw', paddingLeft: '1.25vw'}}> About Us Maintenance </span>
            </div>
            <Grid container>
                <Grid item xs={6}>{displayAboutUsContent()}</Grid>
                <Grid item xs={6}>{displayMissionAndVision()}</Grid>
            </Grid>
            {aboutUsButtons()}
        </div>
    )
}

export default AboutUsMaintenancePage
