import {useEffect, useRef} from "react";

//use this as useEffect to make sure that it will really run only once - this is to prevent previous issue of quota
function useEffectOnce(cb) {
    const didRun = useRef(false)

    useEffect(() => {
        if(!didRun.current) {
            cb()
            didRun.current = true
        }
    })
}

export default useEffectOnce