import React, {useEffect, useState} from 'react'
import useEffectOnce from "../hooks/useEffectOnce"
import {useAppContext} from "../context/AppContext"
import * as actionType from "../util/actionsType"

const BANNER_VIDEO_URL = 'https://firebasestorage.googleapis.com/v0/b/stikwel-prd.appspot.com/o/videos%2FBannerv5-1.m4v?alt=media&token=04f519dd-1a27-47f7-b43b-608bb15113ad'
const BANNER_POSTER = 'images/static-banner.png'

function BannerVideo() {
    const {state, dispatch} = useAppContext()

    useEffectOnce(()=> {
        if(state.bannerVideoUrl){
            let videoElement = document.getElementById('banner')
            videoElement.src = state.bannerVideoUrl
        }
    })

    const onPlaying = () => {
        // placed the video download here so that we are sure that it is playing first (meaning it downloaded already some of the fragments of the video)
        if(!state.bannerVideoUrl) {
            downloadBannerVideo()
        }
    }

    const onEnded = () => {
        // onEnded will never be called if the video has a loop attribute
        // This will be called only once for the rest of user experience
        let videoElement = document.getElementById('banner')
        videoElement.src = state.bannerVideoUrl
        videoElement.poster=""
        videoElement.setAttribute("loop", true)
    }

    const downloadBannerVideo = () => {
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = function (event) {
            if (this.status === 200) {
                const blobUrl = window.URL.createObjectURL(xhr.response)
                dispatch({type: actionType.SET_BANNER_VIDEO_URL, payload: blobUrl})
                let videoElement = document.getElementById('banner')
                //need to remove the loop so it can call the callback END
                videoElement.removeAttribute("loop")
            }
        }
        xhr.open('GET', BANNER_VIDEO_URL, true)
        xhr.send()

    }

    return (
        <div>
            <video id='banner' autoPlay muted loop style={{width: '100%'}}
                   src={BANNER_VIDEO_URL}
                   poster={BANNER_POSTER}
                   onPlaying={onPlaying}
                   onEnded={onEnded}
            />
        </div>
    )
}

export default BannerVideo