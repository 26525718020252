import React from 'react'
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import {useHistory} from "react-router"
import "../styles/NotFoundPage.scss"

function NotFoundPage() {
  const history = useHistory()
  return (
    <Grid id={'not-found-page'} component={'div'} container className={'grid-container-root'}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={'nf-image-container'} component={'div'}>
        <div className={'flex-container'}>
          <div className={'leaf-bg-img'} >
            <div className='missing-puzzle-img'>
              <div className='stikwel-logo-img'>
              </div>
            </div>
          </div>

        </div>
      </Grid>
      <Grid component={'div'} item xs={12} sm={12} md={6} lg={6} xl={6} className='nf-text-container'>
        <div className={'nf-text'}>
          Oops. <br/>
          The Page You Are Looking For Doesn't Exist Or Is Unavailable.
        </div>
        <Button className={'nf-button'} onClick={()=>{history.push('/')}} href={null}>
          Back to Home
        </Button>
      </Grid>
    </Grid>
  )
}

export default NotFoundPage