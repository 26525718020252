import React, {useState} from 'react'
import firebase from "../firebase"
import {Redirect, withRouter} from "react-router-dom"
import {useAuthContext} from "../context/AuthContext"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import {Paper} from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import {FIRESTORE_COLLECTION} from "../util/constants"

const auth = firebase.auth()
const db = firebase.firestore()

function LoginPage({history}) {
    const [login, setLogin] = useState({email: '', password: '', error: {}})
    const [values, setValues] = useState({showPassword: false})

    const {currentUser} = useAuthContext()

    if (currentUser) {
        return <Redirect to="/"/>
    }

    const onChangeInput = (field, event) => {
        setLogin({
            ...login,
            [field]: event.target.value
        })
    }

    const onSubmit = async (event) => {
        event.preventDefault()
        const data = await db.collection(FIRESTORE_COLLECTION.USERS).get()
        const userInDb = data.docs.some(row => row.data().email === login.email)
        if (!userInDb) {
            setLogin({
                ...login,
                error: {message: 'Invalid login, please try again'}
            })
            return
        }
        auth.signInWithEmailAndPassword(login.email, login.password)
            .then(() => {
                history.push('/')
            })
            .catch(error => {
                console.error(error)
                setLogin({
                    ...login,
                    error: error
                })
            })
    }

    return (
        <div style={{height: '100%', width: '100%', backgroundColor: '#C2CBCE', display: 'flex'}}>
            <Grid container style={{backgroundColor: '#81A8B8', height: '700px', width: '970px', margin: 'auto'}}>
                <Grid item xs={6}>
                    <div style={{width: '500px', height: '100%', maxWidth: '100%', maxHeight: '100%'}}>
                        <img alt='product' src={'/images/wood-glue.png'}
                             style={{maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{backgroundColor: '#81A8B8', display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <img style={{
                            width: '165px',
                            height: '85px',
                            filter: 'drop-shadow(2px 2px 2px #222)',
                            margin: 'auto auto 30px auto'
                        }}
                             alt={'nature background'}
                             src={'/images/stikwel-silver-2.svg'}/>

                        <Paper square
                               style={{
                                   width: '17.86vw',
                                   margin: '0 auto auto auto',
                                   boxShadow: '10px 10px 10px #00000029'
                               }}>
                            <div style={{
                                padding: '10px 15px', boxShadow: '0px 3px 6px #00000029',
                                fontSize: '20px', fontWeight: '600', color: '#81A8B8'
                            }}>
                                ADMIN LOGIN
                            </div>
                            <div style={{padding: '38px 35px'}}>
                                <form onSubmit={onSubmit.bind(this)}>
                                    <div>
                                        <TextField
                                            fullWidth
                                            label='Email'
                                            value={login.email}
                                            onChange={onChangeInput.bind(this, 'email')}
                                        />
                                    </div>
                                    <div style={{marginTop: '35px'}}>
                                        <TextField
                                            fullWidth
                                            type={values.showPassword ? 'text' : 'password'}
                                            label='Password'
                                            value={login.password}
                                            onChange={onChangeInput.bind(this, 'password')}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => {
                                                            setValues({...values, showPassword: !values.showPassword})
                                                        }}
                                                        onMouseDown={(event => {
                                                            event.preventDefault()
                                                        })}
                                                    >
                                                        {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                        />
                                    </div>
                                    <Button type="submit" style={{
                                        width: '100%',
                                        marginTop: '30px',
                                        backgroundColor: '#81A8B8',
                                        color: '#FFFFFF'
                                    }}>
                                        SIGN IN
                                    </Button>
                                    <div style={{height: '14px'}}>
                                        {login.error &&
                                        <p style={{color: 'red', fontSize: '12px'}}>{login.error.message}</p>}
                                    </div>
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        </div>

    )
}

export default withRouter(LoginPage)
