import TextField from "@material-ui/core/TextField"
import React from "react"

export default function Input(props) {
  return <div style={{display: 'flex', flexDirection: 'column'}}>
    <TextField
      className={props.className}
      style={{color: '#293127', margin: '0 0 5px 0', ...props.style}}
      label={props.label}
      inputProps={{
        type: props.type,
        maxLength: props.maxLength,
        readOnly: props.readOnly
      }}
      InputProps={{
        disableUnderline: props.readOnly,
        classes: props.additionalStyles
      }}
      helperText={props.readOnly || !props.maxLength ? '' : props.value.length + '/'
        + props.maxLength}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.onChangeFn}
      multiline={props.multiline}
      rows={props.rows}
      rowsMax={props.rowsMax}
      error={props.error}
      fullWidth={props.fullWidth}
    />
  </div>
}