import React from 'react'
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import {useHistory} from "react-router"

function ProductCarouselItem(props) {
    const history = useHistory()
    const item = props.item

    const handleClick = () => {
      if (item.linkURL) {
        history.push('/products/'+item.linkURL)
      }
    }

    return (
      <Paper className='product-item' onClick={handleClick.bind(this)}>
        {props.item.image.imageUrl &&
        <img style={{
          width: '220px',
          height: '220px',
          userDrag: 'none',
          userSelect: 'none',
          MozUserSelect: 'none',
          WebkitUserDrag: 'none',
          WebkitUserSelect: 'none',
          MsUserSelect: 'none'
        }}
           alt={props.item.name + '-product-img'}
           src={props.item.image.imageUrl}/>
        }
        <Typography style={{
          display: 'flex',
          alignItems: 'center',
          textAlign:'center',
          fontSize: '20px',
          fontWeight:'900',
          fontFamily: 'Roboto',
          margin: props.item.image.imageUrl? '30px auto 0': 'auto'
        }}>
          {item.name}
        </Typography>
        </Paper>
    )
}

ProductCarouselItem.propTypes = {}

export default ProductCarouselItem
