import React, {useState} from 'react'
import Grid from "@material-ui/core/Grid"
import Carousel from "react-multi-carousel"
import FacebookIcon from '@material-ui/icons/Facebook'
import YouTubeIcon from '@material-ui/icons/YouTube'
import Paper from "@material-ui/core/Paper"
import {useAppContext} from "../context/AppContext"
import NewsCarouselItem from "../components/NewsCarouselItem"
import Image from "../components/Image"
import ProductCarouselItem from "../components/ProductCarouselItem"
import {Link} from "react-router-dom"
import ProductMenu from "../components/ProductMenu"
import "../styles/HomePage.scss"
import BannerVideo from "../components/BannerVideo";

function HomePage() {
  const {state} = useAppContext()
  const [isOpen, setOpen] = useState(false)
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const [product, setProduct] = useState({})

  const getTitleSection = () => {
    return <>
      <div className={'title-section'}>
        <div className={'h1'}>
          <div>
            <Image className={'stikwel-icon'}
                   alt={'nature background'}
                   src={'/images/stikwel-silver-2.svg'}/>
          </div>
          {state.landingPage?.firstSection?.tagLine
          || 'We Create Eco-Friendly Adhesives'}
        </div>
        <div className={'h2'}>
          {state.landingPage?.firstSection?.paragraph ||
          'Manufacturing cost-effective high-quality industrial water-based adhesives which satisfy customer\'s needs for over 45 years!'}
        </div>
      </div>
      {process.env.REACT_APP_ENV === 'production'
          ? <BannerVideo/> : <img src={'images/static-banner.png'} style={{width: '100%'}}/>
      }
    </>
  }

  const getProductsSection = () => {
    return <div className={'product-section'}>
      <Grid container className='products-container'>
        <Grid item xs={12}>
          <div className='products-section-title'> Our Products </div>
          <Paper elevation={15} className='products-paper-bg'>

            <div className={'products-bg'}>
              <ProductMenu anchorPoint={anchorPoint} isOpen={isOpen} onClose={() => setOpen(false)} menus={product}/>
              <Carousel infinite responsive={{
                largeDesktop: {
                  breakpoint: {max: 1920, min: 1281},
                  items: 5,
                  partialVisibilityGutter: 40
                },
                desktopMd: {
                  breakpoint: {max: 1280, min: 960},
                  items: 3,
                  partialVisibilityGutter: 40
                },
                phone: {
                  breakpoint: {max: 425, min: 1},
                  items: 1,
                  partialVisibilityGutter: 40
                }
              }}>
                {state.menus.map((item, index) =>
                <div className='product-item' key={index}
                     onClick={e => {
                      e.preventDefault()
                      if (item.nodes.length) {
                        setProduct(item)
                        setAnchorPoint({x: e.clientX, y: e.clientY})
                        setOpen(true)
                      }
                }}>
                  <ProductCarouselItem item={item}/>
                </div>)}
              </Carousel>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  }

  const getLastSection = () => {
    return <div className={'last-section'}>
      <div className='section-contents'>
      <div className='section-item'>
      <span className={'section-title'}>
        News
      </span>
        <div className={'section-body news'}>
            <Carousel showDots infinite responsive={{
              desktop: {
                breakpoint: {max: 1920, min: 1281},
                items: 1,
                partialVisibilityGutter: 40
              },
              desktopMd: {
                breakpoint: {max: 1280, min: 960},
                items: 1,
                partialVisibilityGutter: 40
              },
              phone: {
                breakpoint: {max: 425, min: 1},
                items: 1,
                partialVisibilityGutter: 40
              }
            }}>
              {state.news.map(item => <NewsCarouselItem item={item}/>)}
            </Carousel>
        </div>
      </div>
      <div className='section-item'>
      <span className={'section-title'}>
        About Us
      </span>
        <div className={'section-body about-us'}>
          <strong> Consolidated Adhesives, Inc.</strong> <br/>
          Manufactures water-based, environment-friendly and user-safe adhesives
          which are cost effective. As part of our services, we assist our
          customers in analyzing and guiding the proper use of our products
          enhancing their overall productivity. Many solvent-based adhesive
          applications have been successfully replaced with our leading-edge
          adhesives developed over our 45-year history of being in the forefront
          of technological innovations.
        </div>
      </div>
      <div className='section-item'>
      <span className={'section-title'}>
        Careers
      </span>
        <div className={'section-body careers'}>
          Join the company that cares. Leading the water-based adhesives industry for more than 30 years with
          consistently high-quality products.
          <a href={'/careers'} style={{float: 'right', marginTop: 30}}>Learn More</a>
        </div>
      </div>
      </div>
      <Link to={'/contact'} className='contact-link'>
        Want to learn more? Get in touch!
      </Link>
    </div>
  }

  const getHomePageFooter = () => {
    return <div className={'footer-section'}>
      <div className={'copyright'}>
        {"All content ©2020 CONSOLIDATED ADHESIVES, INC. \n" +
        '"Stikwel ®" is an international registered trademark of CONSOLIDATED ADHESIVES, INC.'}
      </div>
      <div className={'links-and-location'}>
        <div className={'icon-container'}>
          <FacebookIcon className={'icon'} onClick={() => window.open(
            "https://www.facebook.com/stikwel2020/", "_blank")}/>
          <YouTubeIcon className={'icon'} onClick={() => window.open(
              "https://youtube.com/channel/UCVYOjUd4cjIG-a7EaXkpbog"
          )}/>
        </div>
        <div className={'location'}>
          {state.contactUs?.address + '\n'
          || '139-D Aurora Blvd. San Juan 1500 Metro Manila Philippines \n'}
          Tel. No.: {state.contactUs?.telephone + '\n'
        || '+(632)872-8797, +(632)874-1511 \n'}
          Fax. No.: {state.contactUs?.fax + '\n\n' || '+(632)874-1486 \n\n'}
          Email: {state.contactUs?.email || 'consolidatedadhesives@gmail.com'}
        </div>
      </div>
    </div>
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {getTitleSection()}
      </Grid>
      <Grid item xs={12}>
        {getProductsSection()}
      </Grid>
      <Grid item xs={12}>
        {getLastSection()}
      </Grid>
      {getHomePageFooter()}
    </Grid>
  )

}

export default HomePage

