import React, {useState, useEffect} from 'react'
import {useAppContext} from "../../context/AppContext"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import Modal from "@material-ui/core/Modal"
import {makeStyles} from "@material-ui/core/styles"
import {useDialogContext} from "../../context/DialogContext"
import {trackPromise} from "react-promise-tracker"
import firebase from "../../firebase"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import ReactQuill from "react-quill"

const db = firebase.firestore()

const useStyles = makeStyles(theme => ({
    paper: {
        width: 900,
        margin: 'auto',
        backgroundColor: theme.palette.background.paper
    },
    titleStyle: {
        padding: '1.042vw 0 1.042vw 1.042vw',
        borderBottom: '1px solid #707070'
    },
    bodyStyle: {
        padding: '1.823vw 2.865vw 0 2.865vw',
        height: 600
    },
    buttonContainerStyle: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-end',
        margin: '40px 50px'
    },
    modalButtonStyle: {
        backgroundColor: '#1F5B84',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalButtonViewStyle: {
        backgroundColor: '#8A8A8A',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalLinkStyle: {
        color: '#717171',
        textDecoration: 'underline',
        fontSize: '0.729vw',
        fontWeight: 550
    }
}))

function ProductDescriptionModal(props) {
    const {state} = useAppContext()
    const classes = useStyles()
    const {showConfirmDialog, showAlert} = useDialogContext()
    const [view, setView] = useState(false)
    const [error, setError] = useState({})
    const [productDescription, setProductDescription] = useState({description: ''})

    useEffect(() => {
        props.data.id && setProductDescription(props.data)
        setView(props.view)
    }, [props.data])

    const displayModalHeader = () => {
        return (
            <Grid container item xs={12} className={classes.titleStyle}>
                <div style={{alignItems: 'center', display: 'flex'}}>
                    <span style={{fontSize: '1.250vw', fontWeight: 'bold'}}>
                        Product Page Description
                    </span>
                </div>
            </Grid>
        )
    }

    const handleModalCancel = (event) => {
        event.preventDefault()
        props.closeFn()
    }

    const onSubmit = async event => {
        event.preventDefault()
        const addEdit = !!productDescription.id ? 'editing' : 'adding'
        trackPromise(
            db.collection(FIRESTORE_COLLECTION.PRODUCT_DESCRIPTION)
                .doc('description').set(productDescription)
            .then(() => showAlert({
                type: 'success',
                title: `Success ${addEdit}`,
                message: `Success ${addEdit} product description!`
            })).catch((error) => {
                setError({message: error})
                showAlert({type: 'error', title: `Error ${addEdit}`, message: `Error ${addEdit} product description`})
        }))
    }

    const save = (event) => {
        const addEdit = !!productDescription.id ? 'edit' : 'add'
        showConfirmDialog({
            title: `${addEdit[0].toUpperCase() + addEdit.substr(1, addEdit.length)} Product Description`,
            message: `Are you sure you want to ${addEdit} Product Description?`,
            confirmFn: () => {
                onSubmit(event)
                props.closeFn()
            },
            type: addEdit
        })
    }

    const onChangeInput = (value) => {
        setProductDescription({ description: value })
    }

    const displayModalBody = () => {
        return (
            <Grid item xs={12} className={classes.bodyStyle}>
                <div className='product-description-container' style={{height:600}}>
                  <ReactQuill value={productDescription.description} style={{
                    border: view ? 'none' : '1px solid #ccc',
                    minHeight: '90%',
                    maxHeight: '90%',
                    overflowY: 'auto'
                  }}
                    modules={{toolbar: view ? '' : ['bold', 'italic', 'underline']}}
                    onChange={onChangeInput} readOnly={view} placeholder={'Compose Main Products Page Description Content...'}/>
                </div>
            </Grid>
        )
    }

    const displayModalButtons = () => {
        const buttonAction = (event) => {
            view ? handleModalCancel(event) : save(event)
        }

        const linkAction = (event) => {
            view ? setView(false) : handleModalCancel(event)
        }

        return (
            <Grid item xs={12} className={classes.buttonContainerStyle}>
                <div style={{paddingBottom: '0.677vw'}}>
                    <Button variant="contained" size="large"
                            className={view ? classes.modalButtonViewStyle : classes.modalButtonStyle}
                            onClick={buttonAction.bind(this)}>
                        {view ? 'Close' : 'Save Changes'}
                    </Button>
                </div>
                <div>
                    <Link className={classes.modalLinkStyle}
                          href='#' onClick={linkAction.bind(this)}>{view ? 'Edit Menu' : 'Cancel'}</Link>
                </div>
            </Grid>
        )
    }

    return (
        <div id={'productDescriptionModal'}>
            {error.message}
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display: 'flex'}}
                className={'modal'}
                open
            >
                <div className={classes.paper}>
                    <Grid container>
                        {displayModalHeader()}
                        {displayModalBody()}
                        {displayModalButtons()}
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}


export default ProductDescriptionModal
