import React, {useEffect, useRef} from "react"
import firebase from "../firebase"
const storage = firebase.storage()

export const copyObject = (object) => {
    return JSON.parse(JSON.stringify(object))
}

export const productEquals = (prevProduct, currProduct) => {
    return JSON.stringify(createProductCopy(prevProduct)) === JSON.stringify(createProductCopy(currProduct))
}

export const deleteDownloadsInFirebaseStorage =  async (row) => {
    for (const downloadItem of row.downloads) {
        await deleteInFirebaseStorage(`downloads/${downloadItem.storageFileName}`)
    }
}
export const deleteInFirebaseStorage = (file) => {
    return storage.ref().child(file).delete()
}

const createProductCopy = (obj) => {
    return {
        id: obj.id,
        name: obj.name,
        details: obj.details,
        videoUrl: obj.videoUrl
    }
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current
}

export const merge = (...obj) => {
    return Object.assign({}, ...obj)
}

export const getVideoId = (url) => {
    const delimiter = isFromAddressBar(url) ? '=' : '/'
    const urlArray = url.split(delimiter)
    const index = isFromAddressBar(url) ? 1 : 3
    return urlArray[index] ? urlArray[index] : ''
}

const isFromAddressBar = (url) => {
    return url.includes('www.youtube.com')
}

export const downloadFile = (download, event) => {
    event.preventDefault()
    let a = document.createElement("a");
    document.body.appendChild(a)
    a.style = "display: none"

    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = function (event) {
        if (this.status === 200) {
            const blob = new Blob([xhr.response], {type: 'application/pdf'})
            const blobUrl = window.URL.createObjectURL(blob)
            a.href = blobUrl;
            a.download = download.displayName;
            a.click();
            window.URL.revokeObjectURL(download.downloadUrl);
        }
    }
    xhr.open('GET', download.downloadUrl, true)
    xhr.send()
}