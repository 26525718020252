import React from 'react'
import {Table as TableMui, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core"
import TableContainer from "@material-ui/core/TableContainer"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Button from "@material-ui/core/Button"
import useWindowSize from "../hooks/useWindowSize";

const cellStyle = {
    display: 'inline-flex',
    boxSizing:'border-box',
    fontSize: '20px',
    alignContent: 'left',
    alignItems:'center',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
}

const headerCellStyle = {
    display: 'inline-flex',
    boxSizing:'border-box',
    width: '100%',
    fontSize: '20px',
    color: '#000000DE',
}

const useStyledTable = makeStyles({
  root: { '&:nth-of-type(even)': {
    backgroundColor: '#F2F2F2',
    border:'none'
  },
  tdhead: {
    backgroundColor: '#000',
    color: '#FFF',
  },
  tdbody: {
    fontSize: 14,
  }
}})

//column keys should be the same with row attributes
function Table(props) {
    const {columns, rows, rowOptions} = props
    const columnKeys = Object.keys(columns)
    const classes = useStyledTable()
    const windowsSize = useWindowSize()

    const calculateHeight = () => {
        return windowsSize.height - 212
    }

    return (
        <div>
            <TableContainer style={{height: calculateHeight()}} className={props.className} component='div'>
                <TableMui stickyHeader>
                    <TableHead>
                        <TableRow style={{display:'flex'}}>
                            {columnKeys.map(key => (
                                <TableCell key={key} style={{...headerCellStyle, ...columns[key].style}}>
                                    {columns[key].label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => <TableRow key={row.id} classes={{root: classes.root}}
                                      style={{height: '80px', border: '1px solid #707070', borderTop:'none', display:'flex'}}>
                              {columnKeys.map((key, index) => {
                                {/*ROW OPTIONS*/}
                                if (key === 'options') {
                                  return <TableCell key={key + index} style={{...cellStyle, ...columns[key].style, borderLeft: '1px solid #707070', fontSize: '20px'}}>
                                    <div style={{
                                      display: 'flex',
                                      justifyContent: 'space-around',
                                      fontWeight: 'bold'
                                    }}>
                                      {rowOptions.map(option => (
                                        <Button href='#' key={option.label} style={{...option.style, fontSize:'20px', fontWeight: 'bold'}}
                                                onClick={option.onClick.bind(this, row)}>{option.label}</Button>
                                      ))}
                                    </div>
                                  </TableCell>
                                }
                                return (
                                  <TableCell key={key + index} style={{...cellStyle, ...columns[key].style}}>
                                    {
                                      columns[key].render ? columns[key].render(row[key]) 
                                        : props.className === 'faq-maint-tbl' && key === 'answer' ? 
                                          <div dangerouslySetInnerHTML={{__html: row[key]}}/> : row[key]
                                    }
                                  </TableCell>
                                )})}
                            </TableRow>
                        )}
                    </TableBody>
                </TableMui>
            </TableContainer>
        </div>
    )
}

export default Table
