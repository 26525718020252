import React from 'react'
import Button from "@material-ui/core/Button"
import InputBase from "@material-ui/core/InputBase"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(()=>({
    inputBaseRoot: {
        fontSize: 14,
        color: '#000000DE',
        opacity: 0.38,
        padding: '15px 10px',
        backgroundColor: '#FAFAFA'
    }
}))

function Upload(props) {
    const classes = useStyles()
    const handleChange = (e) => {
        props.actionFn(e, props.index)
    }

    return (
        <div>
            <div>
                <InputBase
                    classes={{input: classes.inputBaseRoot}}
                    value={props.value}
                />
            </div>
            {props.editable && <label htmlFor={"upload" + props.index}>
                <Button style={{
                    backgroundColor: '#1F5B84',
                    color: '#FFFFFF',
                    width: '112px',
                    height: '1.875vw',
                    fontSize: 14,
                    boxShadow: '0px 2px 2px #0000003D'
                }}
                        variant="raised" component="span">
                    Upload
                </Button>
            </label> }
            <input type="file"
                   name={'upload' + props.index}
                   id={'upload' + props.index}
                   accept={!!props.accept ? props.accept : '*/*'}
                   onChange={handleChange}
                   style={{display:'none'}}
            />
        </div>
    )
}

export default Upload
