import React from 'react'
import {BrowserRouter as Router, Route} from "react-router-dom"
import LoginPage from "./pages/LoginPage"
import {AuthProvider} from "./context/AuthContext"
import PrivateRoute from "./PrivateRoute"
import AdminHomePage from "./pages/AdminHomePage"
import Loading from "./components/Loading"

function Admin() {
    return (
            <AuthProvider>
                <Loading/>
                <Router>
                    <PrivateRoute path="/" component={AdminHomePage}/>
                    <Route exact path="/login" component={LoginPage}/>
                </Router>
            </AuthProvider>
    )
}

export default Admin