import {useState} from "react";

function useModal(callbackFn) {
    const modalInitialState = {open: false, view: false, edit: false, data: {}, closeFn: null}
    const [modal, setModal] = useState(modalInitialState)

    const closeFn = async() => {
        setModal(modalInitialState)
        await callbackFn.onCloseFn()
    }

    const openModal = (isView, data, isNew) => {
        if (isNew) {
            setModal({ ...modalInitialState, open: true, closeFn: closeFn})
            return
        }
        setModal({ open: true, view: isView, edit: !isView, data: data, closeFn: closeFn})
    }

    const newModal = () => {
        openModal(null, null, true)
    }

    const viewModal = (data) => {
        openModal(true, data)
    }

    const editModal = (data) => {
        openModal(false, data, false)
    }

    return {
        isOpen: modal.open,
        viewModal: viewModal,
        editModal: editModal,
        newModal: newModal,
        modal: modal
    }


}

export default useModal