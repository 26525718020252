import React, {useCallback, useEffect, useRef, useState} from 'react'
import SearchBarInput from "./SearchBarInput"
import Image from "./Image"
import {Link, useLocation} from "react-router-dom"
import { useMenuState} from "@szhsin/react-menu"
import ProductMenu from "./ProductMenu";
import {useAppContext} from "../context/AppContext";

function NavBar() {
  const [navBarClass, setNavBarClass] = useState("transparent")
  const { openMenu, closeMenu, ...menuProps } = useMenuState()
  const ref = useRef(null)
  const {state} = useAppContext()
  const location = useLocation()

  const handleScroll = useCallback(() => {
    const background = window.scrollY < 70 ? "transparent" : ""
    setNavBarClass(background)
  }, [])

  useEffect(() => {
    if(location.pathname !== "/"){
      document.removeEventListener("scroll", handleScroll)
      setNavBarClass("")
    } else {
      document.addEventListener("scroll", handleScroll)
      setNavBarClass("transparent")
    }
  }, [location])

  return (
    <div className={"navbar " + navBarClass}>
      {navBarClass === "" && <Image className='navbar-logo' alt={'stikwel-logo'}
                                    src={'/images/stikwel-silver-2.svg'}/>}
      <div className="navbar-menu">
        <ul className="navbar-list">
          <li className="navbar-list-item">
            <Link to="/">Home</Link>
          </li>
          <li className="navbar-list-item"><Link to="/menu-products">Products</Link></li>
          <li className="navbar-list-item"><Link to="/about">About Us</Link></li>
          <li className="navbar-list-item"><Link to="/contact">Contact Us</Link></li>
          <li className="navbar-list-item"><Link to="/careers">Careers</Link></li>
          <li className="navbar-list-item"><Link to="/faq">FAQs</Link></li>
        </ul>
      </div>
      <SearchBarInput/>
    </div>
  )
}

export default NavBar
