import React, {createContext, useContext, useState} from "react"
import firebase from "firebase"
import useEffectOnce from "../hooks/useEffectOnce"

export const AuthContext = createContext()

export const useAuthContext = () => {
    const {currentUser} = useContext(AuthContext)
    return {currentUser}
}

export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState([], () => {
        const localData = localStorage.getItem('userData')
        return localData ? JSON.parse(localData) : []
    })

    useEffectOnce(() => {
        firebase.auth().onAuthStateChanged(setCurrentUser)
        localStorage.setItem('userData', JSON.stringify(currentUser))
    })

    return (
        <AuthContext.Provider
            value={{currentUser}}
        >
            {children}
        </AuthContext.Provider>
    )

}