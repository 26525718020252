import {useAppContext} from "../context/AppContext"
import useEffectOnce from "./useEffectOnce"
import {FIRESTORE_COLLECTION} from "../util/constants"
import firebase from "../firebase"
import * as actionType from "../util/actionsType"
import {trace} from "../util/trace"

const db = firebase.firestore()
const storage = firebase.storage()
const functions = firebase.functions()

function useFetch(firebase_collection) {
    const {dispatch} = useAppContext()

    //TODO refactor this fetch products make it more easy to read
    const fetchProducts = async () => {
        const data = await db.collection('products').get()
        const products = data.docs.map(prodData => ({id: prodData.id, ...prodData.data()}))
        for (const index in products) {
            const imageUrl = products[index].image.imageUrl
            if(!imageUrl){
                console.error("You need to reupload this item to save the URL in firestore , this will save money in calling firebase storage", products[index])
                products[index].image.imageUrl = await storage.ref().child(`images/${products[index].image.storageFileName}`).getDownloadURL().catch((e) => console.error(e))
            }

            for (const downloadIndex in products[index].downloads) {
                const downloadUrl = products[index].downloads[downloadIndex].downloadUrl
                if(!downloadUrl) {
                    console.error("You need to reupload this item to save the URL in firestore , this will save money in calling firebase storage", products[index].downloads[downloadIndex])
                    products[index].image.imageUrl = await storage.ref().child(`downloads/${products[index]
                        .downloads[downloadIndex].storageFileName}`).getDownloadURL().catch((e) => console.error(e))
                }
            }
        }
        dispatch({type: actionType.SET_PRODUCTS, payload: products})
    }

    const fetchProductDescription = async () => {
        const data = await db.collection(FIRESTORE_COLLECTION.PRODUCT_DESCRIPTION).get()
        const productDescriptions = data.docs.map(row => ({id: row.id, ...row.data()}))
        const payload = !!productDescriptions.length ? productDescriptions[0] : {}
        dispatch({type: actionType.SET_PRODUCT_DESCRIPTION, payload: payload})
    }

    const fetchCareers = async () => {
        const data = await db.collection(FIRESTORE_COLLECTION.CAREERS).get()
        const careers = data.docs.map(row => ({id: row.id, ...row.data()}))
        dispatch({type: actionType.SET_CAREERS, payload: careers})
    }

    const fetchFaqs = async () => {
        const data = await db.collection(FIRESTORE_COLLECTION.FAQS).get()
        const faqs = data.docs.map(row => {
            const productName = !!row.data().product ? row.data().product.name : ''
            return {id: row.id, ...row.data(), productName: productName}
        })
        dispatch({type: actionType.SET_FAQS, payload: faqs})
    }

    const fetchAboutUs = async () => {
        const data = await db.collection(FIRESTORE_COLLECTION.ABOUT_US).get()
        const aboutUs = data.docs.map(row => ({id: row.id, ...row.data()}))
        dispatch({type: actionType.SET_ABOUT_US, payload: aboutUs[0]})
    }

    const fetchContactUs = async () => {
        const data = await db.collection(FIRESTORE_COLLECTION.CONTACT_US).get()
        const contactUsData = data.docs.map(row => ({id: row.id, ...row.data()}))
        let contactUs = {}
        contactUsData.forEach(contact => {
            contactUs[contact.id] = contact.displayText
        })
        dispatch({type: actionType.SET_CONTACT_US, payload: contactUs})
    }

    const fetchUsers = async () => {
        //fetch using Firebase Functions because its from Auth and Firestore
        let getAllUsers = functions.httpsCallable('app/getAllUsers', {})
        let users = await getAllUsers()
        users = users.data.map(user => ({...user, lastSignedIn: user.metadata.lastSignInTime}))
        dispatch({type: actionType.SET_USERS, payload: users})
    }

    const fetchNews = async () => {
        const data = await db.collection(FIRESTORE_COLLECTION.NEWS).get()
        const newsRecords = data.docs.map(row => ({id: row.id, ...row.data()}))
        for (const index in newsRecords) {
            //if imageUrl was not saved , then it will get again the downloadURL
            const imageUrl = newsRecords[index].image.imageUrl
            if(!imageUrl){
                console.error("You need to reupload this item to save the URL in firestore , this will save money in calling firebase storage", newsRecords[index])
                newsRecords[index].image.imageUrl = await storage.ref().child(`images/${newsRecords[index].image.storageFileName}`).getDownloadURL().catch((e) => console.error(e))
            }
        }

        dispatch({type: actionType.SET_NEWS, payload: newsRecords})
    }

    const fetchMenus = async () => {
        const data = await db.collection(FIRESTORE_COLLECTION.MENUS).get()
        const menus = data.docs.map(row => ({id: row.id, ...row.data()}))
        const payload = !!menus.length ? (!!menus[0].text ? JSON.parse(menus[0].text) : []) : []
        dispatch({type: actionType.SET_MENUS, payload: payload})
    }

    //make sure to useEffectOnce to prevent infinite loop call to DB
    useEffectOnce(() => {
        switch (firebase_collection) {
            case FIRESTORE_COLLECTION.PRODUCTS:
                trace("getAllProducts", fetchProducts)
                break
            case FIRESTORE_COLLECTION.ABOUT_US:
                trace("getAboutUs", fetchAboutUs)
                break
            case FIRESTORE_COLLECTION.CAREERS:
                trace("getAllCareers", fetchCareers)
                break
            case FIRESTORE_COLLECTION.CONTACT_US:
                trace("getContactUs", fetchContactUs)
                break
            case FIRESTORE_COLLECTION.FAQS:

                trace("getAllFaqs", fetchFaqs)
                //todo concat categories here
                break
            case FIRESTORE_COLLECTION.USERS:
                trace("getAllUsers", fetchUsers)
                break
            case FIRESTORE_COLLECTION.NEWS:
                trace("getAllNews", fetchNews)
                break
            case FIRESTORE_COLLECTION.MENUS:
                trace("getAllMenus", fetchMenus)
                break
            case FIRESTORE_COLLECTION.PRODUCT_DESCRIPTION:
                trace("getProductDescription", fetchProductDescription)
                break
            case FIRESTORE_COLLECTION.ALL:
                //TODO this is not cost efficient
                fetchProducts()
                fetchAboutUs()
                fetchCareers()
                fetchContactUs()
                fetchFaqs()
                fetchNews()
                fetchMenus()
                fetchProductDescription()
                break
            default:
                if(firebase_collection){
                    console.error("NO SUCH FIRESTORE COLLECTION FOR " + firebase_collection)
                    throw new Error()
                }
        }
    })

    return {
        fetchProducts,
        fetchFaqs,
        fetchAboutUs,
        fetchCareers,
        fetchContactUs,
        fetchUsers,
        fetchNews,
        fetchMenus,
        fetchProductDescription
    }

}

export default useFetch
