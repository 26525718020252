import React from 'react'
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  paper: {
    height: '170px',
    padding: '10px'
  },
  paperSm: {
    height: 'unset',
    padding: '10px'
  },
  content: {
    overflow: 'hidden'
  }
}))

function AlertDialog(props) {
    const classes = useStyles()
    return (
        <div id='alert-dialog'>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="draggable-dialog-title"
                //fix for maximun stack error
                disableEnforceFocus
                classes={{
                  paper: classes.paper,
                  paperWidthSm: classes.paperSm
                }}
            >
                <DialogTitle style={{
                    cursor: 'move',
                    fontSize: '20px',
                    lineHeight: '24px',
                    paddingTop:'10px',
                    paddingBottom: '5px'
                }} id="draggable-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent classes={{root: classes.content}}>
                    <DialogContentText style={{fontSize:'16px', lineHeight:'24px', color:'#0000008A'}}>
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{backgroundColor:'#8A8A8A', color: '#FFFFFF'}} onClick={props.onClose}>
                         Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default AlertDialog