import React from "react"
import "../styles/Footer.scss"
import "../styles/FooterMobile.scss"

import Grid from "@material-ui/core/Grid"
import useWindowSize from "../hooks/useWindowSize"
import FacebookIcon from '@material-ui/icons/Facebook'
import YouTubeIcon from '@material-ui/icons/YouTube'
import {useAppContext} from "../context/AppContext"


export default function Footer() {
  const windowSize = useWindowSize()
  const {state} = useAppContext()

  function getFooterDesktop() {
    return <div className={'footer-container'}>
      <div className={'footer-text'}>
        All content ©2020 CONSOLIDATED ADHESIVES, INC. <br/>
        "Stikwel ®" is an international registered trademark of CONSOLIDATED ADHESIVES, INC.
      </div>
    </div>
  }

  function getFooterMobile() {
    return <div className={'footer-section'}>
      <div className={'copyright'}>
        {"All content ©2020 CONSOLIDATED ADHESIVES, INC. \n" +
        '"Stikwel ®" is an international registered trademark of CONSOLIDATED ADHESIVES, INC.'}
      </div>
      <div className={'links-and-location'}>
        <div className={'icon-container'}>
          <FacebookIcon className={'icon'} onClick={() => window.open(
            "https://www.facebook.com/stikwel2020/", "_blank")}/>
          <YouTubeIcon className={'icon'} onClick={() => window.open(
            "https://youtube.com/channel/UCVYOjUd4cjIG-a7EaXkpbog"
          )}/>
        </div>
        <div className={'location'}>
          {state.contactUs?.address + '\n'
          || '139-D Aurora Blvd. San Juan 1500 Metro Manila Philippines \n'}
          Tel. No.: {state.contactUs?.telephone + '\n'
        || '+(632)872-8797, +(632)874-1511 \n'}
          Fax. No.: {state.contactUs?.fax + '\n\n' || '+(632)874-1486 \n\n'}
          Email: {state.contactUs?.email || 'consolidatedadhesives@gmail.com'}
        </div>
      </div>
    </div>
  }

  return (
      <Grid item xl={12} lg={12} sm={12} xs={12} className={'footer'}>
        {windowSize.width > 425 ? getFooterDesktop() : getFooterMobile() }
      </Grid>
    )
}