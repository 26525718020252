import React from 'react'
import NewsModal from "../../components/modals/NewsModal"
import InputBase from "@material-ui/core/InputBase"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import Button from "@material-ui/core/Button"
import Table from "../../components/Table"
import firebase from "../../firebase"
import {useAppContext} from "../../context/AppContext"
import {useDialogContext} from "../../context/DialogContext"
import useFetch from "../../hooks/useFetch"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import useModal from "../../hooks/useModal"
import {trackPromise} from "react-promise-tracker"
import useSearch from "../../hooks/useSearch"

const db = firebase.firestore()

const columns = {
    image: {
        label: "Image",
        style: {width: '150px'},
        render: (image) => {
            return  <img alt={'pic'} src={image.imageUrl} style={{width: '75px', height: '50px'}}/>
        }
    },
    title: {label: "Title", style: {width: '250px'}},
    url: {label: "URL", style: {width: '275px'}},
    active: {label: "Active", style: {width: '100px'}, render: (data) => data ? 'Yes' : 'No'},
    createdAt: {label: "Date Created", style: {width: '250px'}},
    createdBy: {label: "Created By", style: {width: '250px'}},
    options: {label: '', style:{flex:1}}
}

function NewsMaintenancePage() {
    const {state} = useAppContext()
    const {showConfirmDialog, showAlert} = useDialogContext()
    const {fetchNews} = useFetch(FIRESTORE_COLLECTION.NEWS)
    const {isOpen, viewModal, editModal, newModal, modal} = useModal({onCloseFn: () => trackPromise(fetchNews())})
    const {getSearchProps, getResults} = useSearch({
        options : state.news,
        attributesToFilter: ['title']
    })

    const handleNewsEdit = (data) => {
        editModal(data)
    }

    const handleNewsView = (data) => {
        viewModal(data)
    }

    const handleNewsDelete = (row) => {
        trackPromise(db.collection('news').doc(row.id).delete()
            .then(() => {
                showAlert({
                    type: 'success',
                    title: 'Delete Success',
                    message: 'Success deleting news'
                })
                trackPromise(fetchNews())
            }).catch(() =>
                showAlert({
                    type: 'error',
                    title: 'Delete Error',
                    message: 'Error deleting news'
                })
            )
        )
    }

    const removeNews = (row, event) => {
        event.preventDefault()
        showConfirmDialog({
            title: 'Remove News',
            message: 'Are you sure to remove news ' + row.title,
            confirmFn: () => {
                handleNewsDelete(row)
            },
            type: 'remove'
        })
    }
    
    const rowOptions = [{
        label: 'View',
        onClick: handleNewsView
    }, {
        label: 'Edit',
        onClick: handleNewsEdit
    }, {
        label: 'Remove',
        onClick: removeNews,
        style: {color: '#841F1FDE'}
    }]

    
    return (
        <div className={'news-maintenance-page'} style={{backgroundColor: '#FFFFFF', height: '100%', width: '100%'}}>
            {isOpen && <NewsModal {...modal}/>}
            <div style={{height: '4.06vw', borderBottom: '1px solid #707070', display: 'flex', alignItems: 'center'}}>
                <span
                    style={{fontSize: '1.25vw', lineHeight: '1.51vw', paddingLeft: '1.25vw'}}> News Maintenance </span>
            </div>
            <div style={{padding: '1.09vw 0'}}>
                <InputBase
                    style={{
                        border: "1px solid black",
                        width: '18.49vw',
                        height: '2.5vw',
                        marginLeft: '1.4vw',
                        borderRadius: '2px'
                    }}
                    id="news-search-input"
                    placeholder='Search News'
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon style={{margin: '0 1.09vw', width: '0.91vw'}}/>
                        </InputAdornment>
                    }
                    {...getSearchProps}
                    inputProps={{style: {fontSize: '0.73vw'}}}
                />

                <Button style={{
                    float: 'right',
                    marginRight: '2.34vw',
                    backgroundColor: '#1F5B84',
                    color: '#FFFFFF',
                    boxShadow: '0px 2px 2px #0000003D',
                    borderRadius: '2px',
                    fontSize: '0.73vw',
                    textTransform: 'none',
                    width: '8.23vw',
                    height: '1.875vw'
                }}
                        onClick={newModal}>Add New News</Button>
            </div>
            <div>
                <Table className="news-maint-tbl" columns={columns} rows={getResults} rowOptions={rowOptions}/>
            </div>
        </div>
    )
}

export default NewsMaintenancePage
