import React from 'react'
import Grid from "@material-ui/core/Grid"
import Footer from "../components/Footer"
import {Link} from "react-router-dom"
import {useAppContext} from "../context/AppContext"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Typography from "@material-ui/core/Typography"
import '../styles/AboutUsPage.scss'

function AboutUsPage() {
  const {state} = useAppContext()

  const aboutUsImage = () => {
    return (
      <Grid item xs={12} lg={3} md={6} className={'page-images'}>
        <div className='about-us-image-container'>
          <img className={'wood-glue-image'} alt={'wood-glue'}/>
          <img className={'image-bg'} alt={'image-bg'}/>
        </div>
      </Grid>
    )
  }

  const aboutUsContent = () => {
    return (
      <Grid item xs={12} lg={9} md={8} className={'page-content'}>
        <div className='about-us-container'>
            <Breadcrumbs className={'breadcrumbs'}>
              <Link to="/">{`< Back to Home`}</Link>
              <Typography className={'breadcrumbs'}>About Us</Typography>
            </Breadcrumbs>
            <div className={'about-us-title'}>
              Consolidated Adhesives, Inc.
            </div>
          <div>
            <div className={'content'}>
              <div className={'about-us-content'} dangerouslySetInnerHTML={{__html: state.aboutUs.displayText}}/>
              <div className={'mission-vision-container'}>
                <span className={'title'}>Mission</span>
                <div className={'mission-content'} dangerouslySetInnerHTML={{__html: state.aboutUs.mission}}/>
                <span className={'title'}>Our Vision</span>
                <div className={'vision-content'} dangerouslySetInnerHTML={{__html: state.aboutUs.vision}}/>
              </div>
            </div>
            <div className={"see-our-products-link"}>
              <Link to={"/menu-products"} >
                See Our Products
              </Link>
            </div>
          </div>
        </div>
      </Grid>
    )
  }

  return (
    <>
      <Grid className={'about-us-root-container'} container>
          {aboutUsImage()}
          {aboutUsContent()}
        <Footer/>
      </Grid>
    </>
  )
}

export default AboutUsPage