import React from 'react'
import Button from "@material-ui/core/Button"
import FaqModal from "../../components/modals/FaqModal"
import firebase from "../../firebase"
import {useAppContext} from "../../context/AppContext"
import useFetch from "../../hooks/useFetch"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import useModal from "../../hooks/useModal"
import {trackPromise} from "react-promise-tracker"
import InputBase from "@material-ui/core/InputBase"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from '@material-ui/icons/Search'
import {useDialogContext} from "../../context/DialogContext"
import Table from "../../components/Table"
import useSearch from "../../hooks/useSearch"

const db = firebase.firestore()

const columns = {
    displayType : {label: "Question Type", style:{width:'200px'}},
    productName : {label: "Product", style:{width:'250px'}},
    category : {label: "Category", style:{width:'225px'}},
    question : {label: "Question", style:{width:'300px'}},
    answer: {label: "Answer", style:{width:'300px'}},
    options: {label: '', style:{flex:1}}
}

function FaqsMaintenancePage() {
    const {state} = useAppContext()
    const {showConfirmDialog, showAlert} = useDialogContext()
    const { fetchFaqs } = useFetch(FIRESTORE_COLLECTION.FAQS)
    useFetch(FIRESTORE_COLLECTION.PRODUCTS)
    const {isOpen, viewModal, editModal, newModal, modal} = useModal({onCloseFn: () => trackPromise(fetchFaqs())})
    const {getSearchProps, getResults} = useSearch({
        options : state.faqs,
        attributesToFilter: ['question', 'answer']
    })

    const handleFaqEdit = (data) => {
        editModal(data)
    }

    const handleFaqView = (data) => {
        viewModal(data)
    }

    const handleFaqDelete = (row) => {
        trackPromise(db.collection('faqs').doc(row.id).delete()
            .then(() => {
                showAlert({
                    type: 'success',
                    title: 'Delete Success',
                    message: 'Success deleting FAQs'
                })
                trackPromise(fetchFaqs())
            }).catch(() =>
                showAlert({
                    type: 'error',
                    title: 'Delete Error',
                    message: 'Error deleting FAQs'
                })
            )
        )
    }

    const removeFaq = (row, event) => {
        event.preventDefault()
        showConfirmDialog({
            title: 'Remove Faqs',
            message: 'Are you sure to remove this FAQ item?',
            confirmFn: () => {handleFaqDelete(row)},
            type:'remove'
        })
    }

    const rowOptions =  [{
        label: 'View',
        onClick: handleFaqView
    },{
        label: 'Edit',
        onClick: handleFaqEdit
    },{
        label: 'Remove',
        onClick: removeFaq,
        style: {color: '#841F1FDE'}
    }]

  const getTransformedResults = () => {
    return getResults.map(row => ({
      ...row,
      displayType: row.type === "product" ? "Product" : "General"
    }))
  }

  return (
        <div className={'faq-maintenance-page'} style={{backgroundColor: '#FFFFFF', height: '100%', width: '100%'}}>
            {isOpen && <FaqModal {...modal}/>}
            <div style={{height: '4.06vw', borderBottom: '1px solid #707070', display: 'flex', alignItems: 'center'}}>
                <span style={{fontSize: '1.25vw', lineHeight: '1.51vw', paddingLeft: '1.25vw'}}> FAQs Maintenance </span>
            </div>
            <div style={{padding: '1.09vw 0'}}>
                <InputBase
                    style={{border: "1px solid black", width: '18.49vw', height: '2.5vw', marginLeft: '1.4vw', borderRadius: '2px'}}
                    id="faq-search-input"
                    placeholder='Search Faq'
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon style={{margin: '0 1.09vw', width: '0.91vw'}}/>
                        </InputAdornment>
                    }
                    {...getSearchProps}
                    inputProps={{style: {fontSize: '0.73vw'}}}
                />

                <Button style={{
                    float: 'right',
                    marginRight: '2.34vw',
                    backgroundColor: '#1F5B84',
                    color: '#FFFFFF',
                    boxShadow: '0px 2px 2px #0000003D',
                    borderRadius: '2px',
                    fontSize: '0.73vw',
                    textTransform: 'none',
                    width: '8.23vw',
                    height: '1.875vw'
                }}
                        onClick={newModal}>Add New FAQ</Button>
            </div>
            <div>
                <Table className="faq-maint-tbl" columns={columns} rows={getTransformedResults()} rowOptions={rowOptions}/>
            </div>
        </div>
    )
}


export default FaqsMaintenancePage