import React, {createContext, useContext, useState} from "react"
import ConfirmDialog from "../components/ConfirmDialog"
import AlertDialog from "../components/AlertDialog"

export const DialogContext = createContext()

export const useDialogContext = () => {
    const {showConfirmDialog, showAlert} = useContext(DialogContext)
    return { showConfirmDialog, showAlert}
}

//This approach assumes only that you only have one modal and alert at a time.
//Not dynamic yet, but this is the best approach atm because of the UX design
export const DialogProvider = ({children}) => {
    const confirmDialogInitialState = { open: false, title: '', message: '', type: '', confirmFn: null, cancelFn: null }
    const [confirmDialog, setConfirmDialog] = useState(confirmDialogInitialState)
    const alertDialogInitialState = {open: false, title: '', message: '', type: ''}
    const [alertDialog, setAlertDialog] = useState(alertDialogInitialState)

    const showConfirmDialog = (data) => {
        setConfirmDialog({ ...data,  open:true })
    }

    const showAlert = (data) => {
        setAlertDialog({ open: true, ...data })
    }

    return (
        <DialogContext.Provider
            value={{showConfirmDialog, showAlert}}
        >
            <AlertDialog {...alertDialog} onClose={() => setAlertDialog(alertDialogInitialState)}/>
            <ConfirmDialog {...confirmDialog} onClose={() => setConfirmDialog(confirmDialogInitialState)}/>
            {children}
        </DialogContext.Provider>
    )
}
