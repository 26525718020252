import React from 'react'
import Button from "@material-ui/core/Button/index"
import ProductModal from "../../components/modals/ProductModal"
import firebase from "../../firebase"
import InputBase from "@material-ui/core/InputBase"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from '@material-ui/icons/Search'
import {useAppContext} from "../../context/AppContext"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import useFetch from "../../hooks/useFetch"
import useModal from "../../hooks/useModal"
import {trackPromise} from "react-promise-tracker"
import {useDialogContext} from "../../context/DialogContext"
import Table from "../../components/Table"
import {deleteDownloadsInFirebaseStorage, deleteInFirebaseStorage, downloadFile} from "../../util/util"
import useSearch from "../../hooks/useSearch"
import * as actionsType from "../../util/actionsType"
import { KeyboardReturn } from '@material-ui/icons'

const db = firebase.firestore()

const columns = {
    image: {
        label: "Product Image",
        style: {width: '200px'},
        render: (image) => {
            return  <img alt={'pic'} src={image.imageUrl} style={{width: '72px', height: '45px'}}/>
        }
    },
    name: {label: "Product Name", style: {width: '200px'}},
    details: {label: "Product Description",style: {width: '250px'}},
    videoUrl: {label: "Video URL",  style: {width: '300px'}},
    downloads: {
        label: "Downloadable Content",
        style: {width: '300px'},
        render: (downloads) => {
            return downloads.map((download, index) => (
                <span key={download.storageFileName}>
                    <a href={'#'}
                       onClick={downloadFile.bind(this,download)}
                    >{download.displayName}</a>
                </span>
            ))
        }
    },
    options: {label: '', style:{flex:1}}

}

function ProductMaintenancePage() {
    const {state, dispatch} = useAppContext()
    const {showConfirmDialog, showAlert} = useDialogContext()
    const {isOpen, editModal, newModal, viewModal, modal} = useModal({onCloseFn: () => trackPromise(fetchProducts())})
    const {fetchProducts} = useFetch(FIRESTORE_COLLECTION.PRODUCTS)
    const {getSearchProps, getResults} = useSearch({
        options : state.products,
        attributesToFilter: ['name', 'details']
    })

    useFetch(FIRESTORE_COLLECTION.MENUS)

    const removeProduct = (row, event) => {
        event.preventDefault()
        showConfirmDialog({
            title: 'Remove Product',
            message: 'Are you sure to remove product ' + row.name + '?',
            confirmFn: () => {
                handleProductDelete(row)
            },
            type:'remove'
        })
    }

    const handleProductEdit = (data) => {
        editModal(data)
    }

    const handleProductView = (data) => {
        viewModal(data)
    }

    const handleProductDelete = (row) => {
        removeProductFromProductMenu(row.id)
        db.collection('products').doc(row.id).delete()
            .then(() => deleteInFirebaseStorage(`images/${row.image.storageFileName}`))
            .then(() => deleteDownloadsInFirebaseStorage(row))
            .then(() => updateMenuInFirestore())
            .then(() => displayAlert(true))
            .catch(e => displayAlert(false, e))
    }

    const updateMenuInFirestore = () => {
        const newMenus = state.menus
        trackPromise(
            db.collection(FIRESTORE_COLLECTION.MENUS)
                .doc('menus')
                .set({text: JSON.stringify(newMenus)})
        )
    }

    const removeFromTree = (node, productId) => {
        if (node.linkURL === productId) {
            node = undefined
        } else {
            node.nodes.forEach((child, index) => {
                if (!removeFromTree(child, productId)){
                    node.nodes.splice(index, 1)
                }
            })
        }
        return node
    }

    const removeProductFromProductMenu = (productId) => {
        let newMenus = state.menus

        newMenus.map( (menus, index) => {
            if(menus.linkURL === productId) {
                //deleting first level
                newMenus.splice(index, 1)
            }else{
                removeFromTree(menus, productId)
            }
        })
        
        dispatch({type: actionsType.SET_MENUS, payload: newMenus})
    }

    const displayAlert = (success, error) => {
        showAlert({
            type: success ? 'success' : 'error',
            title: success ? 'Delete Success' : 'Delete Error',
            message: success ? 'Success deleting product' : error.message
        })
        trackPromise(fetchProducts())
    }

    const rowOptions = [{
        label: 'View',
        onClick: handleProductView
    }, {
        label: 'Edit',
        onClick: handleProductEdit
    }, {
        label: 'Remove',
        onClick: removeProduct,
        style: {color: '#841F1FDE'}
    }]

    return (
        <div id={'productMaintenance'}
             style={{
                 backgroundColor: '#FFFFFF',
                 height: '100%',
                 width: '100%',
                 border: '1px solid #707070'}}>
            {isOpen && <ProductModal {...modal}/>}

            <div style={{height: '80px', borderBottom: '1px solid #707070', display: 'flex', alignItems: 'center'}}>
                <span style={{fontSize: '24px', lineHeight: '29px', paddingLeft: '25px', fontWeight:'bold'}}> Product Maintenance </span>
            </div>
            <div style={{padding: '20px 0'}}>
                <InputBase
                    style={{border: "1px solid black", width: '340px', height: '48px', marginLeft: '25px', borderRadius: '2px'}}
                    id="products-search-input"
                    placeholder='Search Product'
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon style={{margin: '0 20px', width: '20px'}}/>
                        </InputAdornment>
                    }
                    {...getSearchProps}
                    inputProps={{style: {fontSize: '14px'}}}
                />

                <Button style={{
                    float: 'right',
                    marginRight: '45px',
                    backgroundColor: '#1F5B84',
                    color: '#FFFFFF',
                    boxShadow: '0px 2px 2px #0000003D',
                    borderRadius: '2px',
                    fontSize: '14px',
                    textTransform: 'none',
                    width: '160px',
                    height: '36px'
                }}
                        onClick={newModal}>Add New Product</Button>
            </div>
            <div>
                <Table className="product-maint-tbl" columns={columns} rows={getResults} rowOptions={rowOptions}/>
            </div>
        </div>
    )
}

export default ProductMaintenancePage
