import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import './styles/styles.css'
import './styles/mdc-styles.css'
import 'react-quill/dist/quill.snow.css'
import 'react-multi-carousel/lib/styles.css'
import '@szhsin/react-menu/dist/index.css'
import './styles/common-styles.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter as Router} from 'react-router-dom'
import axios from "axios"
import Admin from "./Admin"
import {AppProvider} from "./context/AppContext"
import {DialogProvider} from "./context/DialogContext"
import {ThemeProvider} from "@material-ui/core/styles"
import STIKWEL_THEME from './styles/theme'
import {MuiPickersUtilsProvider} from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"


axios.interceptors.request.use(function (config) {
    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    return Promise.reject(error)
})

ReactDOM.render(
    <Router>
        <ThemeProvider theme={STIKWEL_THEME}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AppProvider>
                <DialogProvider>
                    {process.env.REACT_APP_ADMIN === 'true' ? <Admin/> : <App/>}
                    {/*<Admin/>*/}
                </DialogProvider>
            </AppProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
    </Router>
    , document.getElementById('stikwel')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
