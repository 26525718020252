import React, {useEffect, useState} from 'react'
import firebase from "../../firebase"
import {makeStyles} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import Modal from "@material-ui/core/Modal"
import {useDialogContext} from "../../context/DialogContext"
import {useAuthContext} from "../../context/AuthContext"
import {merge} from "../../util/util"
import Input from "../Input"
import {trackPromise} from "react-promise-tracker";

const db = firebase.firestore()
const functions = firebase.functions()

const useStyles = makeStyles(theme => ({
    paper: {
        width: 591,
        backgroundColor: theme.palette.background.paper,
        margin:'auto'
    },
    titleStyle: {
        padding: '1.042vw 0 1.042vw 1.042vw',
        borderBottom: '1px solid #707070'
    },
    bodyStyle: {
        padding: '1.823vw 2.865vw 0 2.865vw'
    },
    buttonContainerStyle: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-end',
        margin: '40px 50px'
    },
    modalButtonStyle: {
        backgroundColor: '#1F5B84',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalButtonViewStyle: {
        backgroundColor: '#8A8A8A',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalLinkStyle: {
        color: '#717171',
        textDecoration: 'underline',
        fontSize: '0.729vw',
        fontWeight: 550
    }
}))

const initialUserState = {
    fullName: '',
    email: '',
    createdAt:'',
    createdBy: '',
    lastSignedIn: '',
    password: '',
    confirmPassword: '',
    oldPassword: ''
}

function UserModal(props) {
    const {showConfirmDialog, showAlert} = useDialogContext()
    const classes = useStyles()
    const [user, setUser] = useState(initialUserState)
    const [error, setError] = useState({})
    const [view, setView] = useState(false)
    const {currentUser} = useAuthContext()

    useEffect(() => {
        props.data.id && setUser(merge(props.data, {password: '', confirmPassword: '', oldPassword: ''}))
        setView(props.view)
    }, [props.data])

    const onChangeInput = (field, event) => {
        const value = event.target.value
        setUser({
            ...user,
            [field]: value
        })
    }

    const save = (event) => {
        const addEdit = user.id ? 'edit' : 'add'
        showConfirmDialog({
            title: `${user.id ? 'Edit':'Add'}` + 'User',
            message: 'Are you sure you want to ' + addEdit + ' User?',
            confirmFn: () => {
                onSubmit(event)
                props.closeFn()
            },
            type: addEdit
        })
    }

    const onSubmit = async event => {
        event.preventDefault()
        const action = props.data.id ? 'updateUser' : 'createUser'
        const userAction = functions.httpsCallable('app/' + action, {})
        trackPromise(userAction(user).then(record => {
            const createdTime = props.data.id
                ? props.data.metadata.creationTime
                : record.data.metadata.creationTime
            const createdBy = props.data.id
                ? props.data.createdBy
                : currentUser.email

            const data = {
                fullName: record.data.displayName,
                email: record.data.email,
                createdAt: createdTime,
                createdBy: createdBy
            }
            props.data.id
                ? db.collection('users').doc(user.id).set(data).then(() => displayAlert(true))
                : db.collection('users').add(data).then(() => displayAlert(true))
        }).catch((error) => {
            setError({message: error})
            displayAlert(false)
        }))
    }

    const displayAlert = (success) => {
        let alert

        if(success) {
            if(props.data.id) {
                alert = {type: 'success', title: 'Edit Success', message: 'Success updating user'}
            }else{
                alert = {type: 'success', title: 'Save Success', message: 'Success saving user'}
            }
        } else {
            if(props.data.id) {
                alert = {type: 'error', title: 'Update Error', message: 'Error updating user'}
            }else{
                alert = {type: 'error', title: 'Save Error', message: 'Error saving user'}
            }
        }

        showAlert(alert)
    }

    const handleModalCancel = (event) => {
        event.preventDefault()
        props.closeFn()
    }

    const displayModalHeader = () => {
        let modalTitle = user.id ? 'Edit User' : 'New User'
        if (view) {
            modalTitle = 'View User'
        }

        return (
            <Grid container item xs={12} className={classes.titleStyle}>
                <div style={{alignItems: 'center', display: 'flex'}}>
                    <span style={{fontSize: '1.250vw', fontWeight: 'bold'}}>
                        {modalTitle}
                    </span>
                </div>
            </Grid>
        )
    }

    const displayModalBody = () => {
        const password = () => {
            if(view) {
                return null
            }
            return (
                <div>
                    {
                        user.id &&
                        <div>
                            <Input maxLength={50}
                                   label={'Old Password'}
                                   onChangeFn={onChangeInput.bind(this, 'oldPassword')}
                                   value={user.oldPassword}
                                   error={user.oldPassword.length === 0}
                                   readOnly={view}
                                   type={'password'}
                                   fullWidth
                            />
                        </div>
                    }
                    <div>
                        <Input maxLength={50}
                               label={'New Password'}
                               onChangeFn={onChangeInput.bind(this, 'password')}
                               value={user.password}
                               error={user.password.length === 0}
                               readOnly={view}
                               type={'password'}
                               fullWidth
                        />
                    </div>
                    <div>
                        <Input maxLength={50}
                               label={'Confirm Password'}
                               onChangeFn={onChangeInput.bind(this, 'confirmPassword')}
                               value={user.confirmPassword}
                               error={user.password !== user.confirmPassword}
                               readOnly={view}
                               type={'password'}
                               fullWidth
                        />
                    </div>
                </div>
            )
        }
        return (
            <Grid item xs={12} className={classes.bodyStyle}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Input maxLength={50}
                           label={'Name'}
                           onChangeFn={onChangeInput.bind(this, 'fullName')}
                           value={user.fullName}
                           error={user.fullName.length === 0}
                           readOnly={view}
                           fullWidth
                    />
                </div>
                <Input maxLength={50}
                       label={'Email'}
                       onChangeFn={onChangeInput.bind(this, 'email')}
                       value={user.email}
                       error={user.email.length === 0}
                       readOnly={view || user.id}
                       fullWidth
                />
                {password()}
            </Grid>
        )
    }

    const displayModalButtons = () => {
        const buttonAction = (event) => {
            view ? handleModalCancel(event) : save(event)
        }

        const linkAction = (event) => {
            view ? setView(false) : handleModalCancel(event)
        }

        return (
            <Grid item xs={12} className={classes.buttonContainerStyle}>
                <div style={{paddingBottom: '0.677vw'}}>
                    <Button variant="contained" size="large"
                            className={view ? classes.modalButtonViewStyle : classes.modalButtonStyle}
                            onClick={buttonAction.bind(this)}>
                        {view ? 'Close' : user.id ? 'Save Changes' : 'Add User'}
                    </Button>
                </div>
                <div>
                    <Link className={classes.modalLinkStyle}
                          href='#' onClick={linkAction.bind(this)}>{view ? 'Edit User' : 'Cancel'}</Link>
                </div>
            </Grid>
        )
    }

    return (
        <div id={'userModal'}>
            {error.message}
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={'modal'}
                style={{display:"flex"}}
                open
            >
                <div className={classes.paper}>
                    <Grid container>
                        {displayModalHeader()}
                        {displayModalBody()}
                        {displayModalButtons()}
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

export default UserModal