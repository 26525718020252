import React, {useState} from 'react'
import InputBase from "@material-ui/core/InputBase"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import * as actionsType from "../util/actionsType"
import useAutocomplete from "@material-ui/lab/useAutocomplete"
import {makeStyles} from "@material-ui/core/styles"
import {useHistory} from "react-router-dom"
import {useAppContext} from "../context/AppContext"

const useStyles = makeStyles({
    wrapText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
})

function SearchBarInput() {
    const {state, dispatch} = useAppContext()
    const style = useStyles()
    let history = useHistory()
    const [autoCompleteValue, setAutoCompleteValue] = useState({})

    const onAutoCompleteChange = (event, value) => {
        if (value) {
            history.push("/products/" + value.id)
            setAutoCompleteValue({})
            dispatch({type: actionsType.TOGGLE_NAV_DRAWER, payload: false})
        }
    }

    const {
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: state.products,
        getOptionLabel: option => option.name+option.details,
        onChange: onAutoCompleteChange,
        value: autoCompleteValue,
        //enable this to easily inspect the popup. Select first a value
        // then the popup will not hide
        debug: true
    })

    return (
        <div className='search-bar-input' style={{alignSelf: 'center'}}>
            <InputBase
                className={'input-base'}
                id="sidebar-search"
                placeholder='Search the site'
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon className={'search-icon'}/>
                    </InputAdornment>
                }
                {...getInputProps()}
                open={true}
            />
            {groupedOptions.length > 0 && (
                <ul className={'listbox'} {...getListboxProps()} >
                    {groupedOptions.map((option, index) => (
                        <li key={option.name + index}{...getOptionProps({option, index})}>
                            <div style={{padding: '7px 0px 7px 17px'}}>
                                <div style={{
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    color: '#000000DE'
                                }} className={style.wrapText}>{option.name}</div>
                                <div style={{
                                    color: '#AEAEAEDE',
                                    fontSize: '12px',
                                    lineHeight: '20px'
                                }} className={style.wrapText}>{option.details}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}

        </div>
    )
}

export default SearchBarInput
