import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import Grid from "@material-ui/core/Grid"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import firebase from "../../firebase"
import {useDialogContext} from "../../context/DialogContext"
import Input from "../Input"
import {trackPromise} from "react-promise-tracker"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import {useAppContext} from "../../context/AppContext"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useWindowSize from "../../hooks/useWindowSize"
import ReactQuill from "react-quill"

const db = firebase.firestore()

const useStyles = makeStyles(theme => ({
    paper: {
        width: 591,
        margin: 'auto',
        backgroundColor: theme.palette.background.paper
    },
    titleStyle: {
        padding: '1.042vw 0 1.042vw 1.042vw',
        borderBottom: '0.052vw solid #707070'
    },
    bodyStyle: {
        padding: '1.823vw 2.865vw 0 2.865vw'
    },
    buttonContainerStyle: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-end',
        margin: '2.083vw 2.604vw'
    },
    modalButtonStyle: {
        backgroundColor: '#1F5B84',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalButtonViewStyle: {
        backgroundColor: '#8A8A8A',
        borderRadius: 2,
        opacity: 1,
        color: '#FFFFFF',
        fontSize: '0.729vw',
        width: '8.125vw',
        height: '2.760vw',
        textTransform: 'none'
    },
    modalLinkStyle: {
        color: '#717171',
        textDecoration: 'underline',
        fontSize: '0.729vw',
        fontWeight: 550
    }
}))

function FaqModal(props) {
    const {showConfirmDialog, showAlert} = useDialogContext()
    const {state} = useAppContext()
    const classes = useStyles()
    const [faq, setFaq] = useState({question: '', answer: '', type: 'general', category:'', product : ''})
    const [error, setError] = useState({})
    const [view, setView] = useState(false)
    const windowsSize = useWindowSize()

    useEffect(() => {
        props.data.id && setFaq(props.data)
        setView(props.view)
    }, [props.data])

    const onChangeInput = (field, event) => {
        const value = field === 'answer' ? event : event.target.value
        setFaq({
            ...faq,
            [field]: value
        })
    }

    const save = (event) => {
        const addEdit = faq.id ? 'edit' : 'add'
        showConfirmDialog({
            title:  addEdit === 'edit' ? 'Edit' : 'Add' + ' FAQ',
            message: 'Are you sure you want to ' + addEdit + ' FAQ?',
            confirmFn: () => {
                onSubmit(event)
                props.closeFn()
            },
            type: addEdit
        })
    }

    const onSubmit = event => {
        event.preventDefault()
        // DB saving
        props.data.id
            ? trackPromise(db.collection(FIRESTORE_COLLECTION.FAQS).doc(faq.id).set(faq).then(() => showAlert({
                type: 'success',
                title: 'Edit Success',
                message: 'Success updating FAQ'
            })).catch((error) => {
                setError({message: error})
                showAlert({type: 'error', title: 'Update Error', message: 'Error updating FAQ'})
            }))
            : trackPromise(db.collection(FIRESTORE_COLLECTION.FAQS).add(faq).then(() => showAlert({
                type: 'success',
                title: 'Save Success',
                message: 'Success saving FAQ'
            })).catch((error) => {
                setError({message: error})
                showAlert({type: 'error', title: 'Add Error', message: 'Error adding FAQ'})
            }))
    }

    const handleModalCancel = (event) => {
        event.preventDefault()
        props.closeFn()
    }

    const displayModalHeader = () => {
        let modalTitle = faq.id ? 'Edit FAQ' : 'New FAQ'
        if (view) {
            modalTitle = 'View FAQ'
        }

        return (
            <Grid container item xs={12} className={classes.titleStyle}>
                <div style={{alignItems: 'center', display: 'flex'}}>
                    <span style={{fontSize: '1.250vw', fontWeight: 'bold'}}>
                        {modalTitle}
                    </span>
                </div>
            </Grid>
        )
    }

    const onAutoCompleteChange = (field, event, newValue) => {
        console.log(field, event, newValue)
        setFaq({
            ...faq,
            [field] : newValue
        })
    }

    const onChangeRadioInput = (event, value) => {
        setFaq({
            ...faq,
            type : value,
            product: value === 'general' ? '' : faq.product,
            category: value === 'general' ? faq.category : ''
        })
    }

    const isAutoCompleteError = (type, value) => {
        return faq.type === type && !!value && value.length === 0
    }

    const displayModalBody = () => {
        return (
            <Grid item xs={12} className={classes.bodyStyle}>
                <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '0.833vw'}}>
                        Question Type
                        <RadioGroup  name="type" value={faq.type} onChange={onChangeRadioInput.bind(this)}>
                            <FormControlLabel value="general" control={<Radio />} label="General" disabled={view}/>
                            <FormControlLabel value="product" control={<Radio />} label="Product" disabled={view}/>
                        </RadioGroup>
                    </div>
                    <div style={{marginLeft: '2.604vw', width: '18.229vw'}}>
                        <Autocomplete
                            id="category"
                            freeSolo
                            options={state.faqs.filter(option => !!option.category).map((option) => option.category)}
                            renderInput={(params) => (
                                <TextField {...params}
                                           label="Category"
                                           variant="outlined"
                                           onBlur={onChangeInput.bind(this, 'category')}
                                           error={isAutoCompleteError('general', faq.category)}/>
                            )}
                            value={faq.category}
                            disabled={view || faq.type !== 'general'}
                            onChange={onAutoCompleteChange.bind(this, 'category')}
                        />

                        <Autocomplete
                            id="products"
                            style={{marginTop: '2.083vw'}}
                            options={state.products.map(option => ({id: option.id, name: option.name}))}
                            getOptionLabel={(option) => !!option ? option.name : ''}
                            renderInput={(params) => (
                                <TextField {...params} 
                                           label="Products" 
                                           variant="outlined" 
                                           error={isAutoCompleteError('product', faq.product)}/>
                            )}
                            value={faq.product}
                            disabled={view || faq.type !== 'product'}
                            onChange={onAutoCompleteChange.bind(this, 'product')}
                        />
                    </div>
                </div>
                <div style={{alignItems: 'center', paddingTop: '1.563vw'}}>
                    <Input
                        label={'Question'}
                        onChangeFn={onChangeInput.bind(this, 'question')}
                        value={faq.question}
                        error={faq.question.length === 0}
                        readOnly={view}
                        fullWidth
                    />
                </div>
                <div style={{paddingTop: '1.042vw', height:400}}>
                    <ReactQuill value={faq.answer} 
                        style={{
                            border: view ? 'none' : '1px solid #ccc',
                            minHeight: '90%',
                            maxHeight: '90%',
                            overflowY: 'auto'
                        }}
                        modules={{toolbar: view ? '' : ['bold', 'italic', 'underline']}}
                        onChange={onChangeInput.bind(this, 'answer')} 
                        readOnly={view} 
                        placeholder={'Please add an answer to the FAQ question...'}/>
                </div>
            </Grid>
        )
    }

    const displayModalButtons = () => {
        const buttonAction = (event) => {
            view ? handleModalCancel(event) : save(event)
        }

        const linkAction = (event) => {
            view ? setView(false) : handleModalCancel(event)
        }

        return (
            <Grid item xs={12} className={classes.buttonContainerStyle}>
                <div style={{paddingBottom: '0.677vw'}}>
                    <Button variant="contained" size="large"
                            className={view ? classes.modalButtonViewStyle : classes.modalButtonStyle}
                            onClick={buttonAction.bind(this)}>
                        {view ? 'Close' : faq.id ? 'Save Changes' : 'Add FAQ'}
                    </Button>
                </div>
                <div>
                    <Link className={classes.modalLinkStyle}
                          href='#' onClick={linkAction.bind(this)}>{view ? 'Edit FAQ' : 'Cancel'}</Link>
                </div>
            </Grid>
        )
    }

    return (
        <div id={'faqModal'}>
            {error.message}
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display: 'flex'}}
                className={'modal'}
                open
            >
                <div className={classes.paper}>
                    <Grid container>
                        {displayModalHeader()}
                        {displayModalBody()}
                        {displayModalButtons()}
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

export default FaqModal
