import React from 'react'
import Button from "@material-ui/core/Button"
import UserModal from "../../components/modals/UserModal"
import {useAppContext} from "../../context/AppContext"
import useFetch from "../../hooks/useFetch"
import {FIRESTORE_COLLECTION} from "../../util/constants"
import useModal from "../../hooks/useModal"
import {trackPromise} from "react-promise-tracker"
import InputBase from "@material-ui/core/InputBase"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from '@material-ui/icons/Search'
import {useDialogContext} from "../../context/DialogContext"
import Table from "../../components/Table"
import firebase from "../../firebase"
import useSearch from "../../hooks/useSearch"

const db = firebase.firestore()
const functions = firebase.functions()
const columns = {
    fullName: {label: "Name", style: {width: '300px'}},
    email: {label: "Email", style: {width: '250px'}},
    createdAt: {label: "Date Created", style: {width: '350px'}},
    createdBy: {label: "Created By", style: {width: '200px'}},
    lastSignedIn: {label: "Last Signed In", style: {width: '350px'}},
    options: {label: '', style:{flex:1}}
}

function UsersMaintenancePage() {
    const {state} = useAppContext()
    const {showConfirmDialog, showAlert} = useDialogContext()
    const {fetchUsers} = useFetch(FIRESTORE_COLLECTION.USERS)
    const {isOpen, viewModal, editModal, newModal, modal} = useModal({onCloseFn: () => trackPromise(fetchUsers())})
    const {getSearchProps, getResults} = useSearch({
        options : state.users,
        attributesToFilter: ['fullName', 'email']
    })

    const handleUserEdit = (data) => {
        editModal(data)
    }

    const handleUserView = (data) => {
        viewModal(data)
    }

    const handleUserDelete = (row) => {
        const userAction = functions.httpsCallable('app/deleteUser', {})
        trackPromise(userAction(row.uid).then((result) => {
            if(result.data.success) {
                db.collection('users').doc(row.id).delete()
                    .then(() => {
                        showAlert({
                            type: 'success',
                            title: 'Delete Success',
                            message: 'Success deleting user'
                        })
                        trackPromise(fetchUsers())
                    }).catch(() =>
                        showAlert({
                            type: 'error',
                            title: 'Delete Error',
                            message: 'Error deleting user'
                        })
                    )
            }
        }))
    }

    const removeUser = (row, event) => {
        event.preventDefault()
        showConfirmDialog({
            title: 'Remove User',
            message: 'Are you sure to remove user ' + row.fullName,
            confirmFn: () => {
                handleUserDelete(row)
            },
            type: 'remove'
        })
    }

    const rowOptions = [{
        label: 'View',
        onClick: handleUserView
    }, {
        label: 'Edit',
        onClick: handleUserEdit
    }, {
        label: 'Remove',
        onClick: removeUser,
        style: {color: '#841F1FDE'}
    }]

    return (
        <div className={'user-maintenance-page'} style={{backgroundColor: '#FFFFFF', height: '100%', width: '100%'}}>
            {isOpen && <UserModal {...modal}/>}
            <div style={{height: '4.06vw', borderBottom: '1px solid #707070', display: 'flex', alignItems: 'center'}}>
                <span
                    style={{fontSize: '1.25vw', lineHeight: '1.51vw', paddingLeft: '1.25vw'}}> Users Maintenance </span>
            </div>
            <div style={{padding: '1.09vw 0'}}>
                <InputBase
                    style={{
                        border: "1px solid black",
                        width: '18.49vw',
                        height: '2.5vw',
                        marginLeft: '1.4vw',
                        borderRadius: '2px'
                    }}
                    id="user-search-input"
                    placeholder='Search User'
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon style={{margin: '0 1.09vw', width: '0.91vw'}}/>
                        </InputAdornment>
                    }
                    {...getSearchProps}
                    inputProps={{style: {fontSize: '0.73vw'}}}
                />

                <Button style={{
                    float: 'right',
                    marginRight: '2.34vw',
                    backgroundColor: '#1F5B84',
                    color: '#FFFFFF',
                    boxShadow: '0px 2px 2px #0000003D',
                    borderRadius: '2px',
                    fontSize: '0.73vw',
                    textTransform: 'none',
                    width: '8.23vw',
                    height: '1.875vw'
                }}
                        onClick={newModal}>Add New User</Button>
            </div>
            <div>
                <Table className="user-maint-tbl" columns={columns} rows={getResults} rowOptions={rowOptions}/>
            </div>
        </div>
    )
}

export default UsersMaintenancePage