import React from 'react';
import {useAppContext} from "../context/AppContext"
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Footer from "../components/Footer";
import "../styles/MenuProductsPage.scss"
import Box from "@material-ui/core/Box"
import Image from "../components/Image"

function MenuProductsPage(props) {
    const {state} = useAppContext()

    let displayMenus = (menus) => {
        return menus.map( (menu) => (
          <>
            {
              menu.depth === 0 ?
                menu.linkURL === "" ?
                  <div>
                    <div className={'menu-product-item header'}>{menu.name}</div>
                    <div className={'menu-product-item sub-item'}>
                      <div dangerouslySetInnerHTML={{__html: menu.description}} />
                    </div>
                  </div>
                  : 
                  <div>
                    <Link to={'products/' + menu.linkURL} className={'menu-product-item header'}>
                      {menu.name}
                    </Link>
                  </div>
              :
                menu.linkURL === "" ?
                  <div className={'menu-product-item sub-item'} style={{marginLeft: menu.depth * 15}}>
                    {`${menu.depth >= 2 ? '-' : ''} ${menu.name}`}
                  </div> :
                  <div style={{marginLeft: menu.depth * 15}}>
                    <Link to={'products/' + menu.linkURL} className={'menu-product-item sub-item link'}>
                      • {menu.name}
                    </Link>
                  </div>
            }
            {menu.nodes.length > 0 && displayMenus(menu.nodes)}
          </>
            )
        )
    }
    return (
      <Grid container>
        <Grid item xl={9} lg={8} sm={12} xs={12}>
          <Box className={'menu-products-container'}>
            <Breadcrumbs className={'breadcrumbs'}>
              <Link to="/">{`< Back to Home`}</Link>
              <Typography className={'breadcrumbs'}>Products</Typography>
            </Breadcrumbs>
            <div className='menu-products-title'>
              Our Products
            </div>
            <div className='menu-products-body'>
              <div className='menu-products-description'
                   dangerouslySetInnerHTML={{__html: !!Object.entries(state.productDescription).length? state.productDescription.description : ""}}/>
              <div className='menu-products-list'>
                {displayMenus(state.menus)}
              </div>
              <div className='leave-a-message'>
                Leave us a message for more information on our specific industrial adhesive! We will provide you the
                right type of solution for your bonding needs.
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4}>
          <Box className={'menu-products-container-2'}>
            <div className={'product-image-bg'}>
              <Image src={'/images/conifer-dawn-daylight-evergreen.png'}/>
              <div className={'product-image'}>
                <Image src={'/images/stikwel-products-menu.png'}/>
              </div>
            </div>
          </Box>
        </Grid>
        <Footer/>
      </Grid>
    )
}

export default MenuProductsPage