export const SET_CAREERS = 'SET_CAREERS'
export const SET_USERS = 'SET_USERS'
export const SET_FAQS = 'SET_FAQS'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_ABOUT_US = 'SET_ABOUT_US'
export const SET_NEWS = 'SET_NEWS'
export const SET_LANDING_PAGE = 'SET_LANDING_PAGE'
export const SET_CONTACT_US = 'SET_CONTACT_US'
export const SET_MENUS = 'SET_MENUS'
export const SET_PRODUCT_DESCRIPTION = 'SET_PRODUCT_DESCRIPTION'

export const TOGGLE_NAV_DRAWER = 'TOGGLE_NAV_DRAWER'
export const TOGGLE_NAV_COLLAPSE_PRODUCT = 'TOGGLE_NAV_COLLAPSE_PRODUCT'
export const SHOW_PRODUCT_MENU_MODAL = 'SHOW_PRODUCT_MENU_MODAL'
export const SHOW_PRODUCT_CAROUSEL_MODAL = 'SHOW_PRODUCT_CAROUSEL_MODAL'
export const SET_PRODUCT_CAROUSEL_MODAL_ITEM = 'SET_PRODUCT_CAROUSEL_MODAL_ITEM'

export const SET_CONFIRM_DIALOG = 'SET_CONFIRM_DIALOG'
export const CLOSE_CONFIRM_DIALOG = 'CLOSE_CONFIRM_DIALOG'

export const SET_BANNER_VIDEO_URL = 'SET_BANNER_VIDEO_URL'
