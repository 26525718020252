import React from 'react'
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(()=>({
    root:{
        padding:'10px'
    },
    buttonLabel:{
        fontSize: '14px',
        lineHeight: '20px'
    }
}))

function ConfirmDialog(props) {
    const classes = useStyles()
    const cancelFn = () => {
        if (props.cancelFn) {
            props.cancelFn()
        }
        props.onClose()
    }

    const confirmFn = () => {
        if (props.confirmFn) {
            props.confirmFn()
        }
        props.onClose()
    }

    const getDialogColor = () => {
        if (props.type === 'remove') {
            return '#841F1F'
        }
        if (props.type === 'edit') {
            return '#8A8A8A'
        }
        return '#1F5B84'
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="draggable-dialog-title"
                //fix for maximun stack error
                disableEnforceFocus
                classes={{paper: classes.root}}
            >
                <DialogTitle style={{
                    cursor: 'move',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: props.type === 'edit' ? '#000': getDialogColor(),
                    paddingTop:'10px',
                    paddingBottom: '5px'
                }} id="draggable-dialog-title">
                    {props.title}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{fontSize:'20px', lineHeight:'24px', color:'#0000008A'}}>
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button classes={{label: classes.buttonLabel}}
                            style={{backgroundColor: getDialogColor(), color: '#FFFFFF', width: '85px'}}
                            onClick={confirmFn}>
                        Yes
                    </Button>
                    <Button classes={{label: classes.buttonLabel}} style={{width: '85px'}} autoFocus
                            onClick={cancelFn}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConfirmDialog