import React, {useState} from 'react'
import {makeStyles} from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import {usePromiseTracker} from "react-promise-tracker"

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

function Loading() {
    const { promiseInProgress } = usePromiseTracker()

    const classes = useStyles()
    const [, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }

    return (
        promiseInProgress &&
        <div>
            <Backdrop className={classes.backdrop} open onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Loading